import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_expensecategory() {
  const title = "Add Expense";
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({});

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Category name is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    data.append("name", formData.name);
    data.append("comment", formData.comment);

    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/expense-category/new`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/expensecategory");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      //   console.error("Error:", error);

      console.error("Error:", error.response ? error.response.data : error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-12 mt-3">
                <label htmlFor="dailyOrder">
                  <b>Name *</b>
                </label>
                <input
                  type="text"
                  placeholder="Category Name"
                  className="form-control"
                  name="name"
                  onChange={onChangeHandler}
                  value={formData.name || ""}
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Catgory
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
