import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";

export default function DataTable_Component({ apidata, columns, searchBy }) {
  const [dataFilter, setDataFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [typeFilter, setTypeFilter] = useState("");

  useEffect(() => {
    let filtered = apidata;

    if (typeFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_assettype &&
          item.id_assettype.assetname
            .toLowerCase()
            .includes(typeFilter.toLowerCase())
      );
    }

    if (statusFilter) {
      filtered = filtered.filter(
        (item) =>
          item.status &&
          item.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }
    // Filter by search term
    if (dataFilter) {
      filtered = filtered.filter((item) => {
        // Flexible search logic for different fields
        if (searchBy === "name") {
          return (
            (item.name &&
              item.name.toLowerCase().startsWith(dataFilter.toLowerCase())) ||
            (item.id_user &&
              item.id_user.name
                .toLowerCase()
                .startsWith(dataFilter.toLowerCase())) ||
            (item.id_assettype &&
              item.id_assettype.assetname
                .toLowerCase()
                .startsWith(dataFilter.toLowerCase()))
          );
        }
        // Add more conditions as needed for other fields
        return false;
      });
    }

    setFilteredData(filtered);
  }, [dataFilter, statusFilter, apidata, searchBy, typeFilter]);

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const removeFilters = () => {
    setStatusFilter("");
    setTypeFilter("");
    setShowFilterModal(false);
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        apidata={apidata}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
      />
    </div>
  );
}
