import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/drivers/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Add_data_modal from "../../components/drivers/update_data_modal";
import DriverDetailModal from "../../components/drivers/Driver Details";
import Layouts from "../../layouts/layouts";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";

export default function Driver() {
  const title = "Driver Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [driverCount, setDriverCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showDriverDetailModal, setShowDriverDetailModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const columns = [
    {
      name: <b>Driver Name</b>,
      selector: (row) => (row.id_user ? row.id_user.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Phone Number</b>,
      selector: (row) => (row.id_user ? row.id_user.phonenumber : "N/A"),
      sortable: true,
    },

    {
      name: <b>Joining Date</b>,
      selector: (row) => formatDate(row.joiningDate),
      sortable: true,
    },
    ,
    {
      name: <b>Address</b>,
      selector: (row) => row.permanentaddress,
      sortable: true,
    },
    {
      name: <b>City</b>,
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: <b>Created At</b>,
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: <b>Modified At</b>,
      selector: (row) => row.updatedAt && formatDate(row.updatedAt),
      sortable: true,
    },
    {
      name: <b>Scan Plate</b>,
      selector: (row) => (row.scanNumberPlate ? "True" : "False"),
      cell: (row) => (
        <Switch
          onChange={() => handleToggleScanNumberPlate(row)}
          checked={row.scanNumberPlate}
        />
      ),
      sortable: true,
    },
    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status?.toLowerCase();
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              status === "active"
                ? "btn-success"
                : status === "inactive"
                ? "btn-danger"
                : ""
            }`}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => viewDriver(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchDrivers = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/drivers`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.drivers);
      if (Array.isArray(response.data.drivers)) {
        setApiState(response.data.drivers);
        setDriverCount(response.data.driverCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.log("Error fetching drivers:", error);
    }
  };

  useEffect(() => {
    fetchDrivers(currentPage);
  }, [currentPage]);

  const handleToggleScanNumberPlate = async (driver) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      // Toggle the scanNumberPlate value
      const updatedDriver = {
        ...driver,
        scanNumberPlate: !driver.scanNumberPlate,
      };

      // Update the backend
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/driver/${driver._id}`,
        { scanNumberPlate: updatedDriver.scanNumberPlate },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the local state
      setApiState((prevState) =>
        prevState.map((item) =>
          item._id === driver._id ? { ...item, ...updatedDriver } : item
        )
      );

      Swal.fire("Success!", "Driver scan plate status updated.", "success");
    } catch (error) {
      Swal.fire("Error!", "Failed to update scan plate status.", "error");
      console.error("Error updating driver:", error);
    }
  };

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/driver/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Driver has been deleted.", "success");
            fetchDrivers(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Driver could not be deleted.", "error");
            console.error("Error deleting driver:", error);
          });
      }
    });
  };

  const updateDriver = (updatedDriver) => {
    setApiState((prev) =>
      prev.map((driver) =>
        driver._id === updatedDriver._id ? updatedDriver : driver
      )
    );
  };

  const viewDriver = (driver) => {
    setSelectedDriver(driver);
    setShowDriverDetailModal(true);
  };

  const exportToExcel = () => {
    const transformedData = apistate.map((driver) => ({
      "Driver Name": driver.drivername,
      "Phone Number": driver.phonenumber,
      "Joining Date": formatDate(driver.joiningDate),
      "Exit Date": driver.exitDate,
      Address: driver.permanentaddress,
      "Vendor Name": driver.id_vendor
        ? driver.id_vendor.vendorname
        : "No Vendor",
      City: driver.city,
      "Created At": formatDate(driver.createdAt),
      "Modified At": driver.updatedAt ? formatDate(driver.updatedAt) : "N/A",
      Status: driver.status || "Unknown",
      "Home Number": driver.homenumber || "N/A",
      "Temporary Address": driver.tempaddress || "N/A",
      Country: driver.country || "N/A",
      State: driver.state || "N/A",
      Coordinates: driver.coordinates || "N/A",
      "Created By": driver.createdby || "N/A",
      "Created Date": driver.createddate
        ? formatDate(driver.createddate)
        : "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Drivers");
    XLSX.writeFile(workbook, "DriversData.xlsx");
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateDriver={updateDriver}
      />
      <DriverDetailModal
        show={showDriverDetailModal}
        onHide={() => setShowDriverDetailModal(false)}
        driver={selectedDriver}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create-driver">
                    <div className="btn btn-info btn-sm text-white">
                      Add Driver
                    </div>
                  </Link>
                  <button
                    className="btn btn-success btn-sm ms-2"
                    onClick={exportToExcel}
                  >
                    Export to Excel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    apidata={apistate}
                    // columns={apicol}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
