import React from "react";
import { Modal, Button } from "react-bootstrap";

const SalaryDetailModal = ({ show, onHide, salary }) => {
  if (!salary) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Salary Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>User Name:</strong>{" "}
          {salary.id_user ? salary.id_user.name : "N/A"}
        </p>
        <p>
          <strong>Employee Id:</strong> {salary.id_employee}
        </p>
        <p>
          <strong>Basic Pay:</strong> {salary.basic_pay}
        </p>
        <p>
          <strong>Variable Pay:</strong> {salary.variable_pay}
        </p>
        <p>
          <strong>Total Salary:</strong> {salary.total_salary}
        </p>
        <p>
          <strong>Salary Status:</strong> {salary.salary_status}
        </p>
        <p>
          <strong>PF:</strong> {salary.PF}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SalaryDetailModal;
