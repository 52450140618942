import React from "react";
import { Modal, Button } from "react-bootstrap";

const DamageDetailModal = ({ show, onHide, damage }) => {
  if (!damage) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Damage Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Name:</strong> {damage.id_user ? damage.id_user.name : "N/A"}
        </p>
        <p>
          <strong>Vehicle:</strong>{" "}
          {damage.id_vehicle ? damage.id_vehicle.numberplate : "N/A"}
        </p>
        <p>
          <strong>Category:</strong>{" "}
          {damage.id_category ? damage.id_category.name : "N/A"}
        </p>
        <p>
          <strong>Status:</strong> {damage.status}
        </p>
        <p>
          <strong>Description:</strong> {damage.description}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DamageDetailModal;
