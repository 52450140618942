import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Update_data_modal from "../../components/manageInvoice/update_data_modal/index";
import DataTable_Component from "../../components/tickets/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import Add_data_modal from "../../components/tickets/update_data_modal";
import TicketDetailModal from "../../components/tickets/Ticket Details";
import * as XLSX from "xlsx";

export default function Ticket() {
  const title = "Ticket Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [ticketCount, setTicketCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showTicketDetailModal, setShowTicketDetailModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Vehicle</b>,
      selector: (row) => (row.vehicle_number ? row.vehicle_number : "N/A"),
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.vehicle_number}
        </div>
      ),
      sortable: true,
    },
    {
      name: <b>Assigned To</b>,
      selector: (row) => (row.assigned_to ? row.assigned_to.name : "N/A"),
      sortable: true,
    },

    {
      name: <b>Ticket Issued</b>,
      selector: (row) =>
        formatDate(row.dates ? row.dates.ticket_issued : "N/A"),
      sortable: true,
    },
    {
      name: <b> Ticket Resolved</b>,
      selector: (row) =>
        formatDate(row.dates ? row.dates.ticket_resolved : "N/A"),

      sortable: true,
    },
    {
      name: <b>Heading</b>,
      selector: (row) => row.heading,
      sortable: true,
    },
    {
      name: <b>Description</b>,
      selector: (row) => row.description,
      sortable: true,
    },

    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status?.toLowerCase();
        return (
          <button
            className={`btn px-2 py-1 ${
              // Adjusted padding for space
              status === "In-Progress"
                ? "btn-success"
                : status === "Todo"
                ? "btn-danger"
                : status === "Done"
                ? "btn-danger"
                : ""
            }`}
            style={{
              minWidth: "70px", // Increased minimum width to fit longer text
              fontSize: "14px", // Adjust font size if needed
              whiteSpace: "nowrap", // Prevents text from wrapping to new lines
            }}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
      // Adding width for the column itself to avoid overlap
      width: "150px", // Adjust width as needed
    },

    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>

          <button
            onClick={() => viewTicket(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const exportToExcel = () => {
    const transformedData = apistate.map((ticket) => ({
      "Vehicle Number": ticket.vehicle_number,
      "Assigned To": ticket.assigned_to ? ticket.assigned_to.name : "No User",
      Status: ticket.status,
      Heading: ticket.heading,
      "Ticket Issued": ticket.dates ? ticket.dates.ticket_issued : "-",
      "Ticket Resolved": ticket.dates ? ticket.dates.ticket_resolved : "-",
      Description: ticket.description,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    XLSX.writeFile(workbook, "orders.xlsx");
  };

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/vehicle-tickets`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.tickets)) {
        setApiState(response.data.tickets);
        setTicketCount(response.data.ticketCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v2/vehicle-ticket/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Order has been deleted.", "success");
            fetchData(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Order could not be deleted.", "error");
            console.error("Error deleting order:", error);
          });
      }
    });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const viewTicket = (ticket) => {
    setSelectedTicket(ticket);
    setShowTicketDetailModal(true);
  };
  const updateTicket = (updatedTicket) => {
    setApiState((prev) =>
      prev.map((ticket) =>
        ticket._id === updatedTicket._id ? updatedTicket : ticket
      )
    );
  };

  const handleUpdateTicket = (updatedTicketData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/v2/vehicle-ticket/${updatedTicketData._id}`,
        updatedTicketData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        Swal.fire("Updated!", "Ticket details have been updated.", "success");
        updateTicket(response.data.order);
      })
      .catch((error) => {
        Swal.fire("Error!", "Ticket details could not be updated.", "error");
        console.error(
          "Error updating driver:",
          error.response ? error.response.data : error.message
        );
      });
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateTicket={updateTicket}
      />
      <TicketDetailModal
        show={showTicketDetailModal}
        onHide={() => setShowTicketDetailModal(false)}
        ticket={selectedTicket}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create-ticket">
                    <div className="btn btn-info btn-sm text-white me-2">
                      Add Ticket
                    </div>
                  </Link>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm text-white"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="assigned_to"
                    title_btn={true}
                    title="Ticket List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
