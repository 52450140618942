import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/salaries/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import Add_data_modal from "../../components/salaries/update_data_modal";
import SalaryDetailModal from "../../components/salaries/Salary Details";

export default function Salary() {
  const title = "Salary Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [salaryCount, setSalaryCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showSalaryDetailModal, setShowSalaryDetailModal] = useState(false);
  const [selectedSalary, setSelectedSalary] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Employee ID</b>,
      selector: (row) => row.id_employee,
      sortable: true,
    },
    {
      name: <b>User Name</b>,
      selector: (row) => (row.id_user ? row.id_user.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Basic Pay</b>,
      selector: (row) => row.basic_pay,
      sortable: true,
    },
    {
      name: <b>Variable Pay</b>,
      selector: (row) => row.variable_pay,
      sortable: true,
    },
    {
      name: <b>Total Salary</b>,
      selector: (row) => row.total_salary,
      sortable: true,
    },
    {
      name: <b>PF</b>,
      selector: (row) => row.PF,
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => viewSalary(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v2/salaries`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.salaries)) {
        setApiState(response.data.salaries);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const viewSalary = (salary) => {
    setSelectedSalary(salary);
    setShowSalaryDetailModal(true);
  };
  const updateSalary = (updatedSalary) => {
    setApiState((prev) =>
      prev.map((salary) =>
        salary._id === updatedSalary._id ? updatedSalary : salary
      )
    );
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v2/salary/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Salary has been deleted.", "success");
            fetchData(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Salary could not be deleted.", "error");
            console.error("Error deleting salary:", error);
          });
      }
    });
  };
  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateSalary={updateSalary}
      />
      <SalaryDetailModal
        show={showSalaryDetailModal}
        onHide={() => setShowSalaryDetailModal(false)}
        salary={selectedSalary}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <Link to="/create-salary">
                  <div className="btn btn-info btn-sm text-white">
                    Add Salary
                  </div>
                </Link>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    title="Salary List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
