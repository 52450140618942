import React from "react";
import { Modal, Button } from "react-bootstrap";

const TicketDetailModal = ({ show, onHide, ticket }) => {
  if (!ticket) return null;
  const formatDate = (date) => {
    if (!date) return "N/A";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ticket Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Vehicle Number:</strong> {ticket.vehicle_number}
        </p>
        <p>
          <strong>Assigned To :</strong> {ticket.assigned_to?.name}
        </p>
        <p>
          <strong>Ticket Issued:</strong>{" "}
          {formatDate(ticket.dates?.ticket_issued)}
        </p>
        <p>
          <strong>Ticket Resolved:</strong>{" "}
          {formatDate(ticket.dates?.ticket_resolved)}
        </p>
        <p>
          <strong>Heading:</strong> {ticket.heading}
        </p>
        <p>
          <strong>Status:</strong> {ticket.status}
        </p>
        <p>
          <strong>Description:</strong> {ticket.description}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketDetailModal;
