import React from "react";
import { Modal, Button } from "react-bootstrap";

const AdvanceDetailModal = ({ show, onHide, advance }) => {
  if (!advance) return null;

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Advance Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Name:</strong>{" "}
          {advance.id_user ? advance.id_user.name : "N/A"}
        </p>
        <p>
          <strong>Hub:</strong> {advance.id_hub ? advance.id_hub.name : "N/A"}
        </p>
        <p>
          <strong>Amount:</strong> {advance.advance_amount}
        </p>
        <p>
          <strong>Month:</strong> {advance.month}
        </p>
        <p>
          <strong>Date:</strong>{" "}
          {advance.date ? formatDate(advance.date) : "N/A"}
        </p>
        <p>
          <strong>Comments:</strong> {advance.comments}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvanceDetailModal;
