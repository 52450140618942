import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default function FilterModal({
  show,
  onHide,
  categoryFilter,
  setCategoryFilter,
  vehicleFilter,
  setVehicleFilter,
  applyFilters,
  removeFilters,
  apidata,
}) {
  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  const handleVehicleChange = (e) => {
    setVehicleFilter(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="categoryFilter">
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
            >
              <option value="">All Categories</option>
              {apidata &&
                Array.from(
                  new Set(apidata.map((item) => item.id_category?.name))
                )
                  .filter(Boolean)
                  .map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="vehicleFilter">
            <Form.Label>Vehicle</Form.Label>
            <Form.Control
              as="select"
              value={vehicleFilter}
              onChange={(e) => setVehicleFilter(e.target.value)}
            >
              <option value="">All Vehicles</option>
              {apidata &&
                Array.from(
                  new Set(apidata.map((item) => item.id_vehicle?.numberplate))
                )
                  .filter(Boolean)
                  .map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
