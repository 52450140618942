import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const CalculatorModal = ({ show, onHide }) => {
  const [input, setInput] = useState("");

  // Function to handle input updates
  const handleInput = (value) => {
    setInput(input + value);
  };

  // Calculate result
  const calculate = () => {
    try {
      setInput(eval(input).toString());
    } catch {
      setInput("Error");
    }
  };

  // Clear input
  const clearInput = () => {
    setInput("");
  };

  // Handle key press events
  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key } = event;
      if (!isNaN(key) || ["+", "-", "*", "/"].includes(key)) {
        handleInput(key);
      } else if (key === "Enter") {
        calculate();
      } else if (key === "Backspace") {
        setInput(input.slice(0, -1));
      } else if (key === "Escape") {
        onHide();
      }
    };

    if (show) {
      window.addEventListener("keydown", handleKeyPress);
    }

    // Cleanup event listener on close
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [show, input]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Calculator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          value={input}
          readOnly
          className="form-control mb-3"
        />
        <div className="calculator-buttons">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "+", "-", "*", ".", "=", "/"].map(
            (btn, idx) => (
              <button
                key={idx}
                onClick={() =>
                  btn === "=" ? calculate() : handleInput(btn.toString())
                }
                className="btn btn-secondary m-1"
              >
                {btn}
              </button>
            )
          )}
          <button onClick={clearInput} className="btn btn-danger m-1">
            Clear
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalculatorModal;
