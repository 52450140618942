import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Select from "react-select";

import Layouts from "../../layouts/layouts";

export default function Create_ticket() {
  const title = "Add Ticket";
  const [formData, setFormData] = useState({
    vehicle_number: "",
    assigned_to: "",
    status: "",
    description: "",
    heading: "",
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  const brad = [{ name: "Home" }, { name: title }];

  // const onChangeHandler = (event) => {
  //   const { name, value, files } = event.target;
  //   if (name === "latitude" || name === "longitude") {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       coordinates: {
  //         ...prevFormData.coordinates,
  //         [name]: value,
  //       },
  //     }));
  //   } else if (name === "hubimage") {
  //     const file = files[0];
  //     if (file.size > 1048576) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         hubimage: "Image size must not exceed 1MB",
  //       }));
  //     } else {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         hubimage: "",
  //       }));
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         hubimage: file,
  //       }));
  //     }
  //   } else {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [name]: value,
  //     }));
  //   }
  // };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.vehicle_number)
      newErrors.vehicle_number = "Vehicle Number is required";
    if (!formData.assigned_to)
      newErrors.assigned_to = "Assigned To is required";
    if (!formData.status) newErrors.status = "Status is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const formDataToSubmit = new FormData();
      formDataToSubmit.append("vehicle_number", formData.vehicle_number);
      formDataToSubmit.append("assigned_to", formData.assigned_to);
      formDataToSubmit.append("status", formData.status);
      formDataToSubmit.append("heading", formData.heading);
      formDataToSubmit.append("description", formData.description);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/vehicle-ticket/new`,
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/ticket");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);
  const handleUserChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      assigned_to: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.vehicles);
        setVehicles(response.data.vehicles);
        console.log("vehicles fetched:", response.data.vehicles);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        setVehicles([]);
      }
    };
    fetchVehicles();
  }, []);
  const handleVehicleChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      vehicle_number: selectedOption ? selectedOption.label : "", // Save numberplate directly
    }));
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="vehicle_number">
                  <b>Vehicle *</b>
                </label>
                <Select
                  name="vehicle_number"
                  options={vehicles.map((vehicle) => ({
                    value: vehicle._id,
                    label: `${vehicle.numberplate}`,
                  }))}
                  onChange={(selectedOption) =>
                    handleVehicleChange(selectedOption)
                  }
                  classNamePrefix="select"
                />
                {errors.vehicle_number && (
                  <p className="text-danger">{errors.vehicle_number}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="assigned_to">
                  <b>User ID * </b>
                </label>
                <Select
                  name="assigned_to"
                  options={users.map((user) => ({
                    value: user._id,
                    label: `${user.name || "Unknown Name"} (${
                      user.phonenumber || "Unknown Phone"
                    })`,
                  }))}
                  onChange={handleUserChange}
                  classNamePrefix="select"
                />
                {errors.assigned_to && (
                  <p className="text-danger">{errors.assigned_to}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="heading">
                  <b>Heading *</b>
                </label>
                <input
                  type="text"
                  name="heading"
                  placeholder="Heading"
                  className="form-control"
                  value={formData.heading}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b> Status *</b>
                </label>
                <select
                  name="status"
                  className={`form-control ${
                    errors.status ? "is-invalid" : ""
                  }`}
                  value={formData.status}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Status</option>
                  <option value="In-Progress">In-Progress</option>
                  <option value="Todo">Todo</option>
                  <option value="Done">Done</option>
                </select>
                {errors.status && (
                  <div className="invalid-feedback">{errors.status}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="description">
                  <b>Description *</b>
                </label>
                <input
                  type="text"
                  name="description"
                  placeholder="Description"
                  className="form-control"
                  value={formData.description}
                  onChange={onChangeHandler}
                />
                {errors.heading && (
                  <small className="text-danger">{errors.heading}</small>
                )}
              </div>

              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Ticket
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
