import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateSalary,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: select_data.id_user._id,
      }));
    }
  }, [select_data, users]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "id_user") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: { ...prevData.id_user, [name]: value },
      }));
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!update_modal_data.id_user) newErrors.id_user = "User Name is required";
    if (!update_modal_data.id_employee)
      newErrors.id_employee = "Employee Id is required";
    if (!update_modal_data.total_salary)
      newErrors.total_salary = "Salary is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }

    try {
      const { _id } = update_modal_data;
      if (!_id) {
        throw new Error("ID is not defined");
      }

      const formData = new FormData();
      formData.append("id_user", update_modal_data.id_user);
      formData.append("id_employee", update_modal_data.id_employee);
      formData.append("basic_pay", update_modal_data.basic_pay);
      formData.append("variable_pay", update_modal_data.variable_pay);
      formData.append("total_salary", update_modal_data.total_salary);
      formData.append("PF", update_modal_data.PF);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v2/salary/${_id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        Swal.fire("Update success", "", "success");
        onUpdateSalary(update_modal_data); // Update locally
        setRen(!ren); // Trigger rerender if necessary
      } else {
        Swal.fire("Update failed", "", "error");
      }

      set_update_data_modal_Show(false); // Close modal on success or failure
    } catch (error) {
      Swal.fire("Update failed", "", "error");
      console.error("Error updating salary:", error);
    }
  };
  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);
  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_user: value,
    }));
  };
  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Salary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="id_user">
              <Form.Label>User *</Form.Label>
              <Form.Control
                as="select"
                name="id_user"
                value={update_modal_data.id_user || ""}
                onChange={handleUserChange}
                isInvalid={!!errors.id_user}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {/* {user.name} */}
                    {user.name || "Unknown Name"} (
                    {user.phonenumber || "Unknown Phone"} )
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_user}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Employee ID *</Form.Label>
              <Form.Control
                type="text"
                name="id_employee"
                value={update_modal_data.id_employee || ""}
                onChange={handleChange}
              />
              {errors.id_employee && (
                <small className="text-danger">{errors.id_employee}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Basic Pay </Form.Label>
              <Form.Control
                type="number"
                name="basic_pay"
                value={update_modal_data.basic_pay || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Variable Pay </Form.Label>
              <Form.Control
                type="number"
                name="variable_pay"
                value={update_modal_data.variable_pay || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Total Salary </Form.Label>
              <Form.Control
                type="number"
                name="total_salary"
                value={update_modal_data.total_salary || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>PF </Form.Label>
              <Form.Control
                type="text"
                name="PF"
                value={update_modal_data.PF || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Salary
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
