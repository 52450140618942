import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/damages/Data Table";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Update_data_modal from "../../components/manageInvoice/update_data_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Add_data_modal from "../../components/damages/update_data_modal";
import Layouts from "../../layouts/layouts";
import DamageDetailModal from "../../components/damages/Damage Details";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export

export default function Damage() {
  const title = "Damage Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showDamageDetailModal, setShowDamageDetailModal] = useState(false);
  const [selectedDamage, setSelectedDamage] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      name: <b>User Name</b>,
      selector: (row) => (row.id_user ? row.id_user.name : "-"),
      sortable: true,
    },

    {
      name: <b>Vehicle</b>,
      selector: (row) => (row.id_vehicle ? row.id_vehicle.numberplate : "N/A"),
      sortable: true,
    },
    {
      name: <b>Category</b>,
      selector: (row) => (row.id_category ? row.id_category.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Description </b>,
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status?.toLowerCase();
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              status === "Pending"
                ? "btn-success"
                : status === "Done"
                ? "btn-danger"
                : ""
            }`}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          {row.status === "created" && (
            <>
              <button
                onClick={() => copyLink()}
                className=" btn btn-success btn-sm"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              <button className=" btn btn-primary btn-sm ms-1">
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
            </>
          )}
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className=" btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewDamage(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchDamages = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/damage`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.damages)) {
        setApiState(response.data.damages);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.log("Error fetching damages:", error);
    }
  };

  useEffect(() => {
    fetchDamages(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/damage/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Damage has been deleted.", "success");
            fetchDamages(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Damage could not be deleted.", "error");
            console.error("Error deleting damages:", error);
          });
      }
    });
  };

  const updateDamage = (updatedDamage) => {
    setApiState((prev) =>
      prev.map((damage) =>
        damage._id === updateDamage._id ? updateDamage : damage
      )
    );
  };

  const handleUpdateDamage = (updatedDamageData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/damage/${updatedDamageData._id}`,
        updatedDamageData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        Swal.fire("Updated!", "Damage details have been updated.", "success");
        updateDamage(response.data.damage);
      })
      .catch((error) => {
        Swal.fire("Error!", "Damage details could not be updated.", "error");
        console.error(
          "Error updating damage:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const viewDamage = (damage) => {
    setSelectedDamage(damage);
    setShowDamageDetailModal(true);
  };

  const exportToExcel = () => {
    const transformedData = apistate.map((damage) => ({
      "User Name": damage.id_user ? damage.id_user.name : "No User",
      "Vehicle Number": damage.id_vehicle
        ? damage.id_vehicle.numberplate
        : "No Plate",
      "Category Name": damage.id_category
        ? damage.id_category.name
        : "No Category",
      Description: damage.description,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Damages");

    XLSX.writeFile(workbook, "damages.xlsx");
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateDamage={updateDamage}
      />
      <DamageDetailModal
        show={showDamageDetailModal}
        onHide={() => setShowDamageDetailModal(false)}
        damage={selectedDamage}
      />

      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create_damage">
                    <div className="btn btn-info btn-sm text-white me-2">
                      Add Damage
                    </div>
                  </Link>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm text-white"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    apidata={apistate}
                    // columns={apicol}
                    columns={columns}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
