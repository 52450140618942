import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

export default function Create_vehicle() {
  const title = "Add Vehicle";
  const [formData, setFormData] = useState({
    status: "Active",
    numberplate: "",
    model: "",
    year: "",
    insuranceimage: "",
    vehicleimage: "",
    color: "",
    coordinates: { latitude: "", longitude: "" },
    id_driver: [],
    id_hub: "",
    // totalkm: "",
    lease: "",
    length: "",
    capacity: "",
    comments: "",
    id_type: "", // New field for vehicle type
  });
  const [errors, setErrors] = useState({});
  const [autocomplete, setAutocomplete] = useState(null);
  const navigate = useNavigate();
  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "latitude" || name === "longitude") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: {
          ...prevFormData.coordinates,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData, [name]: value };

        return updatedFormData;
      });
    }
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle/new`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/role");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title} </div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="numberplate">
                  <b>Number Plate *</b>
                </label>
                <input
                  type="text"
                  name="numberplate"
                  placeholder="Number-Plate"
                  className={`form-control ${
                    errors.numberplate ? "is-invalid" : ""
                  }`}
                  onChange={onChangeHandler}
                  value={formData.numberplate}
                />
                {errors.numberplate && (
                  <div className="invalid-feedback">{errors.numberplate}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="model">
                  <b>Model *</b>
                </label>
                <input
                  type="text"
                  name="model"
                  placeholder="Model"
                  className={`form-control ${errors.model ? "is-invalid" : ""}`}
                  value={formData.model}
                  onChange={onChangeHandler}
                />
                {errors.model && (
                  <div className="invalid-feedback">{errors.model}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="insuranceimage">
                  <b>Insurance Image *</b>
                </label>
                <input
                  type="file"
                  name="insuranceimage"
                  placeholder="Insurance Image"
                  className={`form-control ${
                    errors.insuranceimage ? "is-invalid" : ""
                  }`}
                  value={formData.insuranceimage}
                  onChange={onChangeHandler}
                />
                {errors.insuranceimage && (
                  <div className="invalid-feedback">
                    {errors.insuranceimage}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="lease">
                  <b>Lease *</b>
                </label>
                <input
                  type="text"
                  name="lease"
                  placeholder="Lease"
                  className={`form-control ${errors.lease ? "is-invalid" : ""}`}
                  value={formData.lease}
                  onChange={onChangeHandler}
                />
                {errors.lease && (
                  <div className="invalid-feedback">{errors.lease}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="role_title">
                  <b>Role *</b>
                </label>
                <select
                  name="role_title"
                  className={`form-control ${
                    errors.role_title ? "is-invalid" : ""
                  }`}
                  value={formData.role_title}
                  onChange={onChangeHandler}
                >
                  <option value="admin">Admin</option>
                  <option value="clientmanager">Client Manager</option>
                  <option value="user">User</option>
                  <option value="superadmin">Supervisor</option>
                </select>
              </div>
              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Roles
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
