import React from "react";
import { Modal, Button } from "react-bootstrap";

const AssetDetailModal = ({ show, onHide, asset }) => {
  if (!asset) return null;
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Asset Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Asset Type:</strong>{" "}
          {asset.id_assettype
            ? asset.id_assettype.assetname
            : "No asset type assigned"}
        </p>
        <p>
          <strong>User Name:</strong> {asset.id_user?.name || "N/A"}
        </p>
        <p>
          <strong>Date:</strong>{" "}
          {asset.assignedDate ? formatDate(asset.assignedDate) : "N/A"}
        </p>
        <p>
          <strong>Descriptions:</strong> {asset.description}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssetDetailModal;
