import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default function FilterModal({
  show,
  onHide,
  statusFilter,
  setStatusFilter,
  staffTypeFilter,
  setStaffTypeFilter,
  applyFilters,
  removeFilters,
  clientFilter,
  setClientFilter,
  apidata,
}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="clientFilter">
            <Form.Label>Clients</Form.Label>
            <Form.Control
              as="select"
              value={clientFilter || ""}
              onChange={(e) => setClientFilter(e.target.value)}
            >
              <option value="">Clients</option>

              {apidata &&
                Array.from(
                  new Set(
                    apidata.flatMap((item) => {
                      if (Array.isArray(item.id_client)) {
                        return item.id_client.map((client) => client.name);
                      }
                      return [];
                    })
                  )
                )
                  .filter(Boolean) // Ensure all names are valid
                  .map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="statusFilter">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="staffTypeFilter">
            <Form.Label>Staff Type</Form.Label>
            <Form.Control
              as="select"
              value={staffTypeFilter}
              onChange={(e) => setStaffTypeFilter(e.target.value)}
            >
              <option value="">All Staff Types</option>

              {apidata &&
                Array.from(
                  new Set(apidata.map((item) => item.id_generalStaffType?.name))
                )
                  .filter(Boolean)
                  .map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
