import "./layout.css";
import "boxicons";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Admin_icon from "../assets/photo/admin.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronDown,
  faListUl,
  faBuildingColumns,
  faIndent,
  faFileInvoice,
  faFileCode,
  faUsersGear,
  faStore,
  faGear,
  faMobileScreen,
  faGlobe,
  faWrench,
  faLifeRing,
  faGaugeHigh,
  faEye,
  faPenToSquare,
  faEnvelope,
  faBell,
  faRing,
  faKey,
  faPuzzlePiece,
  faUniversalAccess,
  faTree,
  faMessage,
  faListCheck,
  faCircleInfo,
  faPeopleGroup,
  faVideo,
  faBars,
  faX,
  faUsers,
  faTruck,
  faWarehouse,
  faUserTie,
  faSquare,
  faClipboardUser,
  faExchangeAlt,
  faCircleLeft,
  faPersonCircleCheck,
  faPeopleArrows,
  faPeopleRobbery,
  faPerson,
  faFaceDizzy,
  faFaceGrinBeamSweat,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
export default function Layouts() {
  const [plus, setPlus] = useState(true);
  const [plus2, setPlus2] = useState(true);
  const [plus3, setPlus3] = useState(true);
  const [plus4, setPlus4] = useState(true);
  const [plus5, setPlus5] = useState(true);
  const [plus6, setPlus6] = useState(true);
  const pathname = window.location.pathname;
  // const [pathName, setPathName] = useState('/')
  const [render, setRender] = useState(true);
  const [headerToggle, setHeaderToggle] = useState(false);
  const [sidebar, setSidebar] = useState("");
  const [headerNavManu, setheaderNavManu] = useState(true);
  const [email, setEmail] = useState(null);
  useEffect(() => {
    if (
      pathname == "/login" ||
      pathname == "/forgot-password" ||
      pathname == "/register"
    ) {
      setSidebar("hide");
    } else {
      setSidebar(true);
    }
  }, [pathname]);
  const headerTogglehandle = () => {
    setHeaderToggle(!headerToggle);
    setheaderNavManu(!headerNavManu);
  };
  var body_pd = document.getElementById("body-pd");
  useEffect(() => {
    window.innerWidth >= 768 &&
      (headerToggle
        ? body_pd.classList.add("body-pd")
        : body_pd.classList.remove("body-pd"));
    if (document.getElementById("header") !== null) {
      headerToggle &&
        document.getElementById("header").classList.add("body-pd");
      headerToggle !== true &&
        document.getElementById("header").classList.remove("body-pd");
    }
  }, [headerToggle]);

  const updateActive = () => {
    setRender(!render);
  };

  const logoutHandle = () => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          Swal.fire("No token found", "", "error");
          return;
        }
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/user/logout`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              localStorage.removeItem("token"); // Remove token from local storage
              Swal.fire("Logout Success", "", "success").then(() => {
                window.location.href = "/login"; // Redirect to login page
              });
            } else {
              Swal.fire("Logout Failed", "", "error");
            }
          })
          .catch((error) => {
            Swal.fire("Logout Failed", "", "error");
            console.error("Error during logout:", error);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  useEffect(() => {
    // Fetch user's email from the backend API
    const fetchEmail = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const decodedToken = jwtDecode(token);

        const loggedInUserId = decodedToken.id;

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const users = response.data.users;

        // Find the logged-in user's information by matching the ID
        const user = users.find((user) => user._id === loggedInUserId);

        if (user) {
          setEmail(user.email);
        } else {
          console.error("Logged-in user's email not found in response data.");
        }
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    fetchEmail();
  }, []);

  return (
    <div className={sidebar == "hide" ? "d-none" : ""}>
      <header className="header mb-4 dropdown" id="header">
        <div
          onClick={headerTogglehandle}
          className="header_toggle"
          id="header-toggle"
        >
          {headerNavManu ? (
            <FontAwesomeIcon icon={faBars} />
          ) : (
            <FontAwesomeIcon icon={faX} />
          )}{" "}
        </div>
        <div
          className="d-flex align-items-center dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <span className="header_img">
            {" "}
            <img src={Admin_icon} alt="admin icon" />{" "}
          </span>{" "}
          <span className="ms-1">{email}</span>{" "}
        </div>

        <ul
          className="dropdown-menu dropdown-menu-end"
          style={{ width: "auto", padding: "0, 2rem" }}
          aria-labelledby="dropdownMenuButton1"
        >
          <li>
            <Link className="dropdown-item" to="/profileSetting">
              Profile
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/general-setting">
              Setting
            </Link>
          </li>
          <li className="dropdown-item" onClick={logoutHandle}>
            Log Out
          </li>
        </ul>
      </header>

      <div className="manubar">
        <div className={`l-navbar ${headerToggle ? "show" : " "}`} id="nav-bar">
          <nav className="nav">
            <div>
              {" "}
              <a href="#" className="nav_logo">
                {" "}
                <i className="bx bx-layer nav_logo-icon"></i>{" "}
                <span className="nav_logo-name">Admin Panel</span>{" "}
              </a>
              <div className="nav_list">
                <Link
                  onClick={updateActive}
                  to="/"
                  className={`nav_link ${pathname == "/" ? "active" : ""}`}
                >
                  <i className="bx bx-grid-alt nav_icon"></i>
                  <span className="nav_name">Dashboard</span>
                </Link>

                <Link
                  to="/hub"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/hub" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faWarehouse} />
                  <span className="nav_name">Hubs</span>
                </Link>

                <Link
                  to="/client"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/client" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faPersonCircleCheck} />
                  <span className="nav_name">Clients</span>
                </Link>

                <Link
                  to="/generalstaff"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/generalstaff" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">General Staff</span>
                </Link>

                <Link
                  to="/user"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/user" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span className="nav_name">Users</span>
                </Link>

                <Link
                  to="/driver"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/driver" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faPerson} />
                  <span className="nav_name">Drivers</span>
                </Link>

                <Link
                  to="/vendor"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/vendor" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faUserTie} />
                  <span className="nav_name">Vendors</span>
                </Link>

                <Link
                  to="/vehicle"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/vehicle" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faTruck} />
                  <span className="nav_name">Vehicles</span>
                </Link>

                <Link
                  to="/hau"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/hau" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Hub Admins</span>
                </Link>

                <Link
                  onClick={updateActive}
                  to="/activeinactive"
                  className={`nav_link ${pathname == "/" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faPeopleArrows} />
                  <span className="nav_name">Active-Inactive</span>
                </Link>

                <Link
                  to="/swap"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/swap" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                  {/* <FontAwesomeIcon icon={faTruck} /> */}
                  <span className="nav_name">Swapped Vehicles</span>
                </Link>

                <Link
                  to="/advance"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/advance" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                  {/* <FontAwesomeIcon icon={faTruck} /> */}
                  <span className="nav_name">Advance Taken</span>
                </Link>
                <Link
                  to="/backup"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/backup" ? "active" : ""
                  }`}
                >
                  {/* <FontAwesomeIcon icon={faTruck} /> */}
                  <FontAwesomeIcon icon={faCircleLeft} />
                  <span className="nav_name">Backups</span>
                </Link>

                <Link
                  to="/defaulter"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/defaulter" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faPeopleRobbery} />
                  <span className="nav_name">Defaulters</span>
                </Link>

                <Link
                  to="/order"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/order" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Orders / Revenue</span>
                </Link>
                <Link
                  to="/damagecategory"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/damagecategory" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Damage Category</span>
                </Link>

                <Link
                  to="/damage"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/damage" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Damages </span>
                </Link>

                <Link
                  to="/ticket"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/ticket" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Tickets</span>
                </Link>

                <Link
                  to="/expensecategory"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/expensecategory" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Expense Category</span>
                </Link>

                <Link
                  to="/expense"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/expense" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Expenses </span>
                </Link>

                <Link
                  to="/assettype"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/assettype" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Asset Type</span>
                </Link>

                <Link
                  to="/asset"
                  onClick={updateActive}
                  className={`nav_link ${pathname == "/asset" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">Assets </span>
                </Link>

                <Link
                  to="/salary"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/salary" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faWarehouse} />
                  <span className="nav_name">Salary</span>
                </Link>

                <Link
                  to="/allusers"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/allusers" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span className="nav_name">All Users</span>
                </Link>
                <Link
                  to="/utilization"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/utilization" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faSquare} />
                  <span className="nav_name">Utilization</span>
                </Link>

                <Link
                  to="/generalstafftype"
                  onClick={updateActive}
                  className={`nav_link ${
                    pathname == "/generalstafftype" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faClipboardUser} />
                  <span className="nav_name">General Staff Type</span>
                </Link>

                <Link
                  onClick={updateActive}
                  to="/leave"
                  className={`nav_link ${pathname == "/leave" ? "active" : ""}`}
                >
                  <FontAwesomeIcon icon={faFaceGrinBeamSweat} />
                  <span className="nav_name">Leaves</span>
                </Link>

                {/* <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                      <Link
                        onClick={updateActive}
                        to="#"
                        type="button"
                        onClick={() => setPlus(!plus)}
                        data-bs-toggle="collapse"
                        data-bs-target="#paymentSystem"
                        aria-expanded="true"
                        aria-controls="paymentSystem"
                        className={`nav_link `}
                      >
                        <FontAwesomeIcon icon={faGear} />
                        <span className="nav_name ">
                          payment Setting{" "}
                          <b>
                            {plus ? (
                              <FontAwesomeIcon icon={faChevronLeft} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronDown} />
                            )}
                          </b>
                        </span>
                      </Link>
                    </p>
                    <div
                      id="paymentSystem"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Link
                          onClick={updateActive}
                          to="/mobailBank/bKash"
                          className={`nav_link ${
                            pathname == "/mobailBank/bKash" ? "active" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faMobileScreen} />
                          <span className="nav_name">Mobail Bank</span>
                        </Link>
                        <Link
                          onClick={updateActive}
                          to="/manage-bankTransfer"
                          className={`nav_link ${
                            pathname == "/manage-bankTransfer" ? "active" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faBuildingColumns} />
                          <span className="nav_name">Bank Transfar</span>
                        </Link>
                        <Link
                          onClick={updateActive}
                          to="/international-payment/paypal"
                          className={`nav_link ${
                            pathname == "/international-payment/paypal"
                              ? "active"
                              : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faGlobe} />
                          <span className="nav_name">International</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                      <Link
                        onClick={updateActive}
                        to="#"
                        type="button"
                        onClick={() => setPlus2(!plus2)}
                        data-bs-toggle="collapse"
                        data-bs-target="#systemSetting"
                        aria-expanded="true"
                        aria-controls="systemSetting"
                        className={`nav_link `}
                      >
                        <FontAwesomeIcon icon={faWrench} />
                        <span className="nav_name ">
                          System Setting{" "}
                          <b>
                            {plus2 ? (
                              <FontAwesomeIcon icon={faChevronLeft} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronDown} />
                            )}
                          </b>
                        </span>
                      </Link>
                    </p>
                    <div
                      id="systemSetting"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Link
                          onClick={updateActive}
                          to="/system-setting/app-setting"
                          className={`nav_link ${
                            pathname == "/system-setting/app-setting"
                              ? "active"
                              : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faMobileScreen} />
                          <span className="nav_name">Android App</span>
                        </Link>

                        <Link
                          onClick={updateActive}
                          to="/system-setting/templete"
                          className={`nav_link ${
                            pathname == "/system-setting/templete"
                              ? "active"
                              : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faGaugeHigh} />
                          <span className="nav_name">Templete</span>
                        </Link>
                        <Link
                          onClick={updateActive}
                          to="/system-setting/custom-css"
                          className={`nav_link ${
                            pathname == "/system-setting/custom-css"
                              ? "active"
                              : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faFileCode} />
                          <span className="nav_name">Custim CSS</span>
                        </Link>
                        <Link
                          onClick={updateActive}
                          to="/system-setting/mail"
                          className={`nav_link ${
                            pathname == "/system-setting/mail" ? "active" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                          <span className="nav_name">Mail</span>
                        </Link>
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <p className="accordion-header" id="headingOne">
                              <Link
                                onClick={updateActive}
                                to="#"
                                type="button"
                                onClick={() => setPlus3(!plus3)}
                                data-bs-toggle="collapse"
                                data-bs-target="#notification"
                                aria-expanded="true"
                                aria-controls="notification"
                                className={`nav_link `}
                              >
                                <FontAwesomeIcon icon={faBell} />
                                <span className="nav_name ">
                                  Notifications{" "}
                                  <b>
                                    {plus3 ? (
                                      <FontAwesomeIcon icon={faChevronLeft} />
                                    ) : (
                                      <FontAwesomeIcon icon={faChevronDown} />
                                    )}
                                  </b>
                                </span>
                              </Link>
                            </p>
                            <div
                              id="notification"
                              className="accordion-collapse collapse "
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <Link
                                  onClick={updateActive}
                                  to="/notification/onSignal"
                                  className={`nav_link ${
                                    pathname == "/notification/onSignal"
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <FontAwesomeIcon icon={faRing} />
                                  <span className="nav_name">One Signal</span>
                                </Link>
                                <Link
                                  onClick={updateActive}
                                  to="/notification/email"
                                  className={`nav_link ${
                                    pathname == "/notification/email"
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <FontAwesomeIcon icon={faEnvelope} />
                                  <span className="nav_name">Email</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Link
                          onClick={updateActive}
                          to="/system-setting/active-license"
                          className={`nav_link ${
                            pathname == "/system-setting/active-license"
                              ? "active"
                              : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faKey} />
                          <span className="nav_name">Active license</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                      <Link
                        onClick={updateActive}
                        to="#"
                        type="button"
                        onClick={() => setPlus4(!plus4)}
                        data-bs-toggle="collapse"
                        data-bs-target="#addons"
                        aria-expanded="true"
                        aria-controls="addons"
                        className={`nav_link`}
                      >
                        <FontAwesomeIcon icon={faPuzzlePiece} />
                        <span className="nav_name ">
                          Addons{" "}
                          <b>
                            {plus4 ? (
                              <FontAwesomeIcon icon={faChevronLeft} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronDown} />
                            )}
                          </b>
                        </span>
                      </Link>
                    </p>
                    <div
                      id="addons"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Link
                          onClick={updateActive}
                          to="/sms-transaction"
                          className={`nav_link ${
                            pathname == "/sms-transaction" ? "active" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faMessage} />
                          <span className="nav_name">Sms Transaction</span>
                        </Link>
                        <Link
                          onClick={updateActive}
                          to="/sms-setting/bulksmsbd"
                          className={`nav_link ${
                            pathname == "/sms-setting/bulksmsbd" ? "active" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faMessage} />
                          <span className="nav_name">Sms List</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                      <Link
                        onClick={updateActive}
                        to="#"
                        type="button"
                        onClick={() => setPlus6(!plus6)}
                        data-bs-toggle="collapse"
                        data-bs-target="#rollManagement"
                        aria-expanded="true"
                        aria-controls="paymentSystem"
                        className={`nav_link `}
                      >
                        <FontAwesomeIcon icon={faUsersGear} />
                        <span className="nav_name ">
                          Role Management{" "}
                          <b>
                            {plus6 ? (
                              <FontAwesomeIcon icon={faChevronLeft} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronDown} />
                            )}
                          </b>
                        </span>
                      </Link>
                    </p>
                    <div
                      id="rollManagement"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Link
                          onClick={updateActive}
                          to="/role-user"
                          className={`nav_link ${
                            pathname == "/role-user" ? "active" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                          <span className="nav_name">Role Create</span>
                        </Link>
                        <Link
                          onClick={updateActive}
                          to="/role-access"
                          className={`nav_link ${
                            pathname == "/role-access" ? "active" : ""
                          }`}
                        >
                          <FontAwesomeIcon icon={faEye} />
                          <span className="nav_name">Role Access</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  onClick={updateActive}
                  to="/theme-market"
                  className={`nav_link ${
                    pathname == "/theme-market" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faStore} />
                  <span className="nav_name">Theme Market</span>
                </Link>
                <Link
                  onClick={updateActive}
                  to="/change-password"
                  className={`nav_link ${
                    pathname == "/change-password" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faGear} />
                  <span className="nav_name">Change Password</span>
                </Link>
                <Link
                  onClick={updateActive}
                  to="/activities"
                  className={`nav_link ${
                    pathname == "/activities" ? "active" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faListCheck} />
                  <span className="nav_name">Activity Logs</span>
                </Link>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <p className="accordion-header" id="headingOne">
                      <Link
                        onClick={updateActive}
                        to="#"
                        type="button"
                        onClick={() => setPlus5(!plus5)}
                        data-bs-toggle="collapse"
                        data-bs-target="#help"
                        aria-expanded="true"
                        aria-controls="help"
                        className={`nav_link `}
                      >
                        <FontAwesomeIcon icon={faCircleInfo} />
                        <span className="nav_name ">
                          Help{" "}
                          <b>
                            {plus5 ? (
                              <FontAwesomeIcon icon={faChevronLeft} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronDown} />
                            )}
                          </b>
                        </span>
                      </Link>
                    </p>
                    <div
                      id="help"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Link
                          onClick={updateActive}
                          to="/general-setting"
                          className={`nav_link `}
                        >
                          <FontAwesomeIcon icon={faGear} />
                          <span className="nav_name">General Setting</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                <Link onClick={logoutHandle} to="#" className={`nav_link `}>
                  <FontAwesomeIcon icon={faFileInvoice} />
                  <span className="nav_name">Logout</span>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
