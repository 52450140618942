import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_order() {
  const title = "Add Order";
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({
    id_user: "",
    id_vehicle: "",
    dailyOrder: "",
    dailyDeliver: "",
    dailyUndelivered: "",
    orderPicked: "",
    cashSort: "",
    kmDriven: "",
    date: "",
    comment: "",
    id_client: "",
    unPickedOrder: "",
    amount: "",
    shift: "",
  });

  const [errors, setErrors] = useState({});

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.vehicles);
        setVehicles(response.data.vehicles);
        console.log("vehicles fetched:", response.data.vehicles);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        setVehicles([]);
      }
    };
    fetchVehicles();
  }, []);

  const handleVehicleChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_vehicle: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.clients);
        setClients(response.data.clients);
        console.log("Clients fetched:", response.data.clients);
      } catch (error) {
        console.error("Error fetching client:", error);
        setClients([]);
      }
    };
    fetchClients();
  }, []);
  const handleClientChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_client: selectedOption ? selectedOption.value : "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.id_user) {
      newErrors.id_user = "User ID is required.";
    }
    if (!formData.dailyOrder) {
      newErrors.dailyOrder = "Daily Order is required.";
    }
    if (!formData.dailyDeliver) {
      newErrors.dailyDeliver = "Daily Delivered Order is required.";
    }
    if (!formData.id_vehicle) {
      newErrors.id_vehicle = "Vehicle is required.";
    }

    if (!formData.date) {
      newErrors.date = "Date is required.";
    }
    if (!formData.id_client) {
      newErrors.id_client = "Client Id  is required.";
    }
    if (!formData.orderPicked) {
      newErrors.orderPicked = "Order Picked  is required.";
    }
    if (!formData.unPickedOrder) {
      newErrors.unPickedOrder = "Un-Picked Order  is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUserChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_user: selectedOption ? selectedOption.value : "",
    }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    data.append("id_user", formData.id_user);
    data.append("dailyOrder", formData.dailyOrder);
    data.append("dailyDeliver", formData.dailyDeliver);
    data.append("id_vehicle", formData.id_vehicle);
    data.append("date", formData.date);
    data.append("comment", formData.comment);
    data.append("id_client", formData.id_client);
    data.append("orderPicked", formData.orderPicked);
    data.append("unPickedOrder", formData.unPickedOrder);
    data.append("shift", formData.shift);
    data.append("cashSort", formData.cashSort);
    data.append("kmDriven", formData.kmDriven);
    data.append("amount", formData.amount);

    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/order/new`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/order");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      //   console.error("Error:", error);

      console.error("Error:", error.response ? error.response.data : error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="date">
                  <b> Date *</b>
                </label>
                <input
                  type="date"
                  name="date"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.date || ""}
                />
                {errors.date && <p className="text-danger">{errors.date}</p>}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User ID * </b>
                </label>
                <Select
                  name="id_user"
                  options={users.map((user) => ({
                    value: user._id,
                    label: `${user.name || "Unknown Name"} (${
                      user.phonenumber || "Unknown Phone"
                    })`,
                  }))}
                  onChange={handleUserChange}
                  classNamePrefix="select"
                />
                {errors.id_user && (
                  <p className="text-danger">{errors.id_user}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_vehicle">
                  <b>Vehicle *</b>
                </label>
                <Select
                  name="id_vehicle"
                  options={vehicles.map((vehicle) => ({
                    value: vehicle._id,
                    label: `${vehicle.numberplate}`,
                  }))}
                  onChange={(selectedOption) =>
                    handleVehicleChange(selectedOption)
                  }
                  classNamePrefix="select"
                />
                {errors.id_vehicle && (
                  <p className="text-danger">{errors.id_vehicle}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="dailyOrder">
                  <b>Total Orders *</b>
                </label>
                <input
                  type="number"
                  placeholder="Daily Order"
                  className="form-control"
                  name="dailyOrder"
                  onChange={onChangeHandler}
                  value={formData.dailyOrder || ""}
                />
                {errors.dailyOrder && (
                  <p className="text-danger">{errors.dailyOrder}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="dailyDeliver">
                  <b>Daily Deliver *</b>
                </label>
                <input
                  type="number"
                  placeholder="Daily Delivered"
                  className="form-control"
                  name="dailyDeliver"
                  onChange={onChangeHandler}
                  value={formData.dailyDeliver || ""}
                />
                {errors.dailyDeliver && (
                  <p className="text-danger">{errors.dailyDeliver}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="orderPicked">
                  <b> Order Picked * </b>
                </label>
                <input
                  type="number"
                  placeholder="Order Picked "
                  className="form-control"
                  name="orderPicked"
                  onChange={onChangeHandler}
                  value={formData.orderPicked || ""}
                />
                {errors.orderPicked && (
                  <p className="text-danger">{errors.orderPicked}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="unPickedOrder">
                  <b> Order Un-Picked * </b>
                </label>
                <input
                  type="number"
                  placeholder="Order Un-Picked "
                  className="form-control"
                  name="unPickedOrder"
                  onChange={onChangeHandler}
                  value={formData.unPickedOrder || ""}
                />
                {errors.unPickedOrder && (
                  <p className="text-danger">{errors.unPickedOrder}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="cashSort">
                  <b>Cash Short </b>
                </label>
                <input
                  type="number"
                  placeholder="Cash Short "
                  className="form-control"
                  name="cashSort"
                  onChange={onChangeHandler}
                  value={formData.cashSort || ""}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="kmDriven">
                  <b>Km Driven </b>
                </label>
                <input
                  type="number"
                  placeholder="Km Driven "
                  className="form-control"
                  name="kmDriven"
                  onChange={onChangeHandler}
                  value={formData.kmDriven || ""}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="amount">
                  <b>Amount </b>
                </label>
                <input
                  type="number"
                  placeholder="Amount "
                  className="form-control"
                  name="amount"
                  onChange={onChangeHandler}
                  value={formData.amount || ""}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_client">
                  <b>Client * </b>
                </label>
                <Select
                  name="id_client"
                  options={clients.map((client) => ({
                    value: client._id,
                    label: `${client.name} (${
                      client.id_hub?.name || "Unknown Hub "
                    })`,
                  }))}
                  onChange={(selectedOption) =>
                    handleClientChange(selectedOption)
                  }
                  classNamePrefix="select"
                />
                {errors.id_client && (
                  <p className="text-danger">{errors.id_client}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="shift">
                  <b> Shift Timings * </b>
                </label>
                <select
                  name="shift"
                  className="form-control"
                  value={formData.shift || "Active"}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Shift</option>
                  <option value="Morning">Morning</option>
                  <option value="Afternoon">Afternoon</option>
                  <option value="Evening">Evening</option>
                </select>
              </div>
              <div className="col-md-6 mt-3"></div>
              <div className="col-12 mt-3">
                <label htmlFor="comment">
                  <b>Comments</b>
                </label>
                <textarea
                  name="comment"
                  type="text"
                  placeholder="Comments"
                  rows="5"
                  className="form-control"
                  value={formData.comment}
                  onChange={onChangeHandler}
                ></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Order
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
