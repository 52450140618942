import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/assettypes/Data Table";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import { Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Add_data_modal from "../../components/assettypes/update_data_modal";
import Update_data_modal from "../../components/manageInvoice/update_data_modal/index";
import AssetDetailModal from "../../components/assettypes/Asset Details";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";

export default function AssetType() {
  const title = "Assets Type";

  const [apistate, setApiState] = useState([]); // Ensure this is an array initially
  const [apicol, setApiCol] = useState([]); // Ensure this is an array initially
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [showAssetDetailModal, setShowAssetDetailModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const brad = [{ name: "Home" }, { name: title }];

  const columns = [
    {
      name: <b>Asset Name</b>,
      selector: (row) => row.assetname,
      sortable: true,
    },
    {
      name: <b>Cost</b>,
      selector: (row) => row.cost,
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          {row.status === "created" && (
            <>
              <button
                onClick={() => copyLink()}
                className=" btn btn-success btn-sm"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              <button className=" btn btn-primary btn-sm ms-1">
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
            </>
          )}
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className=" btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewAsset(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        ` ${process.env.REACT_APP_BASE_URL}/api/v1/asset-types`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.assetTypes)) {
        setApiState(response.data.assetTypes);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };
  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/asset-type/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Asset has been deleted.", "success");
            fetchData(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Asset could not be deleted.", "error");
            console.error("Error deleting asset:", error);
          });
      }
    });
  };

  const updateAsset = (updatedAsset) => {
    setApiState((prev) =>
      prev.map((asset) => (asset._id === updateAsset._id ? updateAsset : asset))
    );
  };

  const handleUpdateAsset = (updatedAssetData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/asset-type/${updatedAssetData._id}`,
        updatedAssetData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        Swal.fire("Updated!", "Asset details have been updated.", "success");
        updateAsset(response.data.asset);
      })
      .catch((error) => {
        Swal.fire("Error!", "Asset details could not be updated.", "error");
        console.error(
          "Error updating asset:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const viewAsset = (asset) => {
    setSelectedAsset(asset);
    setShowAssetDetailModal(true);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />

      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateAsset={updateAsset}
      />
      <AssetDetailModal
        show={showAssetDetailModal}
        onHide={() => setShowAssetDetailModal(false)}
        asset={selectedAsset}
      />

      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <Link to="/create_assettype">
                  <div className="btn btn-info btn-sm text-white">
                    Asset Type
                  </div>
                </Link>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="assetname"
                    title_btn={true}
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
