import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";

export default function DataTable_Component({ apidata, columns, searchBy }) {
  const [dataFilter, setDataFilter] = useState("");
  const [staffTypeFilter, setStaffTypeFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    let filtered = apidata;

    if (staffTypeFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_user?.id_generalStaffType &&
          item.id_user?.id_generalStaffType?.name
            .toLowerCase()
            .includes(staffTypeFilter.toLowerCase())
      );
    }
    if (dataFilter) {
      filtered = filtered.filter((item) => {
        if (searchBy === "name") {
          return (
            item.name &&
            item.name.toLowerCase().startsWith(dataFilter.toLowerCase())
          );
        }

        return false;
      });
    }

    setFilteredData(filtered);
  }, [dataFilter, staffTypeFilter, apidata, searchBy]);

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const removeFilters = () => {
    setStaffTypeFilter("");
    setShowFilterModal(false);
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        staffTypeFilter={staffTypeFilter}
        setStaffTypeFilter={setStaffTypeFilter}
        staffTypes={staffTypeFilter}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        apidata={apidata}
      />
    </div>
  );
}
