import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumb from "../../components/breadcrumb/index";
import Layouts from "../../layouts/layouts";
import { State, City } from "country-state-city";
export default function CreateGeneralstaffs() {
  const title = "Add Staff";
  const [formData, setFormData] = useState({
    homenumber: "",
    tempaddress: "",
    city: "",
    state: "",
    permanentaddress: "",
    governmentid: null,
    perpacket: "",
    salary: "",
    description: "",
    id_user: "",
    joiningDate: null,
    exitDate: "",
  });
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const navigate = useNavigate();
  const countryCode = "IN"; // Fixed country code for India

  useEffect(() => {
    const stateData = State.getStatesOfCountry(countryCode).map((state) => ({
      name: state.name,
      code: state.isoCode,
    }));
    setStates(stateData);
    setCities([]);
  }, [countryCode]);

  useEffect(() => {
    if (selectedStateCode) {
      const cityData = City.getCitiesOfState(
        countryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, countryCode]);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const onChangeHandler = (event) => {
    const { type, name, value, files } = event.target;
    if (type === "file") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (name === "state") {
      setSelectedStateCode(value);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  const validateForm = () => {
    let formErrors = {};
    if (!formData.homenumber) {
      formErrors.homenumber = "Home number is required.";
    } else if (!/^\d{10}$/.test(formData.homenumber)) {
      formErrors.homenumber = "Home number must be 10 digits.";
    }
    if (!formData.tempaddress)
      formErrors.tempaddress = "Temporary address is required.";
    if (!formData.state) formErrors.state = "State is required.";
    if (!formData.city) formErrors.city = "City is required.";
    if (!formData.permanentaddress)
      formErrors.permanentaddress = "Permanent address is required.";
    if (!formData.id_user) formErrors.id_user = "User is required.";
    if (!formData.perpacket) {
      formErrors.perpacket = "Per packet value is required.";
    } else if (isNaN(formData.perpacket)) {
      formErrors.perpacket = "Per packet value must be a number.";
    }
    if (!formData.joiningDate)
      formErrors.joiningDate = "Joining date is required.";
    setErrors(formErrors);
    console.log("Validation Errors:", formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    console.log("Form submitted");

    if (!validateForm()) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("homenumber", formData.homenumber);
    formDataToSend.append("tempaddress", formData.tempaddress);
    formDataToSend.append("state", formData.state);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("permanentaddress", formData.permanentaddress);
    formDataToSend.append("governmentid", formData.governmentid);
    formDataToSend.append("perpacket", formData.perpacket);
    formDataToSend.append("salary", formData.salary);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("id_user", formData.id_user);
    formDataToSend.append("joiningDate", formData.joiningDate);
    formDataToSend.append("exitDate", formData.exitDate);
    console.log("Submitting form data:", formDataToSend);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff/new`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("API RESPONSE", response.data);

      if (response.status === 201) {
        navigate("/generalstaff");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDateChange = (date, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: date,
    }));
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title} </div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="homenumber">
                  <b>Alternate Phone Number *</b>
                </label>
                <input
                  type="text"
                  name="homenumber"
                  placeholder="Enter Home Number"
                  className="form-control"
                  value={formData.homenumber}
                  onChange={onChangeHandler}
                />
                {errors.homenumber && (
                  <p className="text-danger">{errors.homenumber}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="tempaddress">
                  <b>Temporary Address *</b>
                </label>
                <input
                  type="text"
                  name="tempaddress"
                  placeholder="Address"
                  className="form-control"
                  value={formData.tempaddress}
                  onChange={onChangeHandler}
                />
                {errors.tempaddress && (
                  <p className="text-danger">{errors.tempaddress}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="state">
                  <b>State *</b>
                </label>
                <select
                  name="state"
                  className="form-control"
                  value={formData.state || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && <p className="text-danger">{errors.state}</p>}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="city">
                  <b>City *</b>
                </label>
                <select
                  name="city"
                  className="form-control"
                  value={formData.city || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.code} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {errors.city && <p className="text-danger">{errors.city}</p>}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User *</b>
                </label>
                <select
                  name="id_user"
                  className="form-control"
                  value={formData.id_user}
                  onChange={onChangeHandler}
                >
                  <option value="">Select User</option>
                  {Array.isArray(users) &&
                    users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.email}
                      </option>
                    ))}
                </select>
                {errors.id_user && (
                  <p className="text-danger">{errors.id_user}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="permanentaddress">
                  <b>Permanent Address *</b>
                </label>
                <input
                  type="text"
                  name="permanentaddress"
                  placeholder="Address"
                  className="form-control"
                  value={formData.permanentaddress}
                  onChange={onChangeHandler}
                />
                {errors.permanentaddress && (
                  <p className="text-danger">{errors.permanentaddress}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="governmentid">
                  <b>Government ID</b>
                </label>
                <input
                  type="file"
                  name="governmentid"
                  placeholder="Govt. ID"
                  className="form-control"
                  onChange={onChangeHandler}
                />
                {errors.governmentid && (
                  <p className="text-danger">{errors.governmentid}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="perpacket">
                  <b>Per Packet *</b>
                </label>
                <input
                  type="number"
                  name="perpacket"
                  placeholder="Per Packet"
                  className="form-control"
                  value={formData.perpacket}
                  onChange={onChangeHandler}
                />
                {errors.perpacket && (
                  <p className="text-danger">{errors.perpacket}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="salary">
                  <b>Salary </b>
                </label>
                <input
                  type="text"
                  name="salary"
                  placeholder="Salary"
                  className="form-control"
                  value={formData.salary}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="description">
                  <b>Description </b>
                </label>
                <input
                  type="text"
                  name="description"
                  placeholder="Description"
                  className="form-control"
                  value={formData.description}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="joiningDate">
                  <b>Joining Date *</b>
                </label>
                <input
                  type="date"
                  name="joiningDate"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.joiningDate || ""}
                />
                {errors.joiningDate && (
                  <p className="text-danger">{errors.joiningDate}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="exitDate">
                  <b>Exit Date </b>
                </label>
                <input
                  type="date"
                  name="exitDate"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.exitDate || ""}
                />
              </div>
              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add General Staff
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
