import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateTicket,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);

      setUpdate_modal_data((prevData) => ({
        ...prevData,
        // Set vehicle_number by matching it with the numberplate from the vehicles array
        vehicle_number:
          vehicles.find(
            (vehicle) => vehicle.numberplate === select_data.vehicle_number
          )?.numberplate || "",
        // Set assigned_to by matching it with the user ID
        assigned_to: select_data.assigned_to?._id || "",
        dates: select_data.dates._id || "",
      }));
    }
  }, [select_data, vehicles, users]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };

    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVehicles(response.data.vehicles || []);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        setVehicles([]);
      }
    };

    fetchUsers();
    fetchVehicles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "vehicle_number") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        vehicle_number: { ...prevData.vehicle_number, [name]: value },
      }));
    } else if (name === "assigned_to") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        assigned_to: { ...prevData.assigned_to, [name]: value },
      }));
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      assigned_to: value,
    }));
  };

  const handleVehicleChange = (e) => {
    const selectedVehicleId = e.target.value; // Get the selected vehicle ID
    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle._id === selectedVehicleId
    );
    if (selectedVehicle) {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        vehicle_number: selectedVehicle.numberplate, // Set the numberplate instead of the ID
      }));
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!update_modal_data.assigned_to)
      newErrors.assigned_to = "Assigned ID is required";
    if (!update_modal_data.status) newErrors.status = "Status is required";

    if (!update_modal_data.vehicle_number) {
      errors.vehicle_number = "Vehicle is required";
    }
    if (!update_modal_data.heading) {
      errors.heading = "Heading is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          // Create a new FormData object
          const formData = new FormData();
          formData.append("assigned_to", update_modal_data.assigned_to);
          formData.append("vehicle_number", update_modal_data.vehicle_number);
          formData.append("status", update_modal_data.status);
          formData.append("description", update_modal_data.description);
          formData.append("heading", update_modal_data.heading);

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v2/vehicle-ticket/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateTicket(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating ticket:", error);
        }
        set_update_data_modal_Show(false);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Ticket Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="assigned_to">
              <Form.Label>User *</Form.Label>
              <Form.Control
                as="select"
                name="assigned_to"
                value={update_modal_data.assigned_to || ""}
                onChange={handleUserChange}
                isInvalid={!!errors.assigned_to}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {/* {user.name} */}
                    {user.name || "Unknown Name"} (
                    {user.phonenumber || "Unknown Phone"} )
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.assigned_to}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="id_client">
              <Form.Label>Vehicle *</Form.Label>
              <Form.Control
                as="select"
                name="vehicle_number"
                // value={update_modal_data.vehicle_number || ""}
                value={
                  vehicles.find(
                    (vehicle) =>
                      vehicle.numberplate === update_modal_data.vehicle_number
                  )?._id || ""
                }
                onChange={handleVehicleChange}
                isInvalid={!!errors.vehicle_number}
              >
                <option value="">Select Vehicle</option>
                {vehicles.map((vehicle) => (
                  <option key={vehicle._id} value={vehicle._id}>
                    {vehicle.numberplate}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.vehicle_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={update_modal_data.status || ""}
                onChange={handleChange}
                isInvalid={!!errors.status}
              >
                <option value="In-Progress">In-Progress</option>
                <option value="Todo">Todo</option>
                <option value="Done">Done</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Heading </Form.Label>
              <Form.Control
                type="text"
                name="heading"
                value={update_modal_data.heading || ""}
                onChange={handleChange}
                isInvalid={!!errors.heading}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description </Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={update_modal_data.description || ""}
                onChange={handleChange}
                isInvalid={!!errors.description}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Ticket
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
