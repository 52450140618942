import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_expense() {
  const title = "Add Expenses";
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [category, setCategory] = useState([]);
  const [formData, setFormData] = useState({
    id_user: "",
    date: null,
    id_hub: "",
    id_expenseCategory: "",
    amount: "",
    comments: "",
    transactionType: "credit",
  });

  const [errors, setErrors] = useState({});

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.hubs);
        setHubs(response.data.hubs);
        console.log("Hubs fetched:", response.data.hubs);
      } catch (error) {
        console.error("Error fetching hubs:", error);
        setHubs([]);
      }
    };
    fetchHubs();
  }, []);
  const handleHubChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_hub: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/expense-categories`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.expenseCategories);
        setCategory(response.data.expenseCategories);
        console.log("Hubs fetched:", response.data.expenseCategories);
      } catch (error) {
        console.error("Error fetching expenseCategories:", error);
        setCategory([]);
      }
    };
    fetchCategory();
  }, []);
  const handleCategoryChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_expenseCategory: selectedOption ? selectedOption.value : "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.id_user) {
      newErrors.id_user = "User ID is required.";
    }
    if (!formData.amount) {
      newErrors.amount = "Amount is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    data.append("id_user", formData.id_user);
    data.append("comments", formData.comments);
    data.append("date", formData.date);
    data.append("amount", formData.amount);
    data.append("id_hub", formData.id_hub);
    data.append("id_expenseCategory", formData.id_expenseCategory);
    data.append("transactionType", formData.transactionType);
    console.log("Form Data:", formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/expense/new`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/expense");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      //   console.error("Error:", error);

      console.error("Error:", error.response ? error.response.data : error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User Name *</b>
                </label>
                <Select
                  name="id_user"
                  options={users.map((user) => ({
                    value: user._id,
                    label: `${user.name || "Unknown Name"} (${
                      user.phonenumber || "Unknown Phone"
                    })`,
                  }))}
                  onChange={handleSelectChange}
                  classNamePrefix="select"
                />
                {errors.id_user && (
                  <div className="invalid-feedback">{errors.id_user}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_hub">
                  <b>Assign Hub *</b>
                </label>
                <Select
                  name="id_hub"
                  options={hubs.map((hub) => ({
                    value: hub._id,
                    label: `${hub.name}`,
                  }))}
                  onChange={handleHubChange} // Use the specific handler
                  classNamePrefix="select"
                />
                {errors.id_hub && (
                  <div className="invalid-feedback">{errors.id_hub}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_expenseCategory">
                  <b>Assign Category *</b>
                </label>
                <Select
                  name="id_expenseCategory"
                  options={category.map((category) => ({
                    value: category._id,
                    label: `${category.name}`,
                  }))}
                  onChange={handleCategoryChange} // Use the specific handler
                  classNamePrefix="select"
                />
                {errors.id_expenseCategory && (
                  <div className="invalid-feedback">
                    {errors.id_expenseCategory}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="amount">
                  <b>Amount *</b>
                </label>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control"
                  name="amount"
                  onChange={onChangeHandler}
                  value={formData.amount || ""}
                />
                {errors.amount && (
                  <p className="text-danger">{errors.amount}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="date">
                  <b>Date *</b>
                </label>
                <input
                  type="date"
                  name="date"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.date || ""}
                />
                {errors.joiningDate && (
                  <p className="text-danger">{errors.date}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="transactionType">
                  <b> Driver Transaction Type *</b>
                </label>
                <select
                  name="transactionType"
                  className="form-control"
                  value={formData.transactionType || ""}
                  onChange={onChangeHandler}
                >
                  <option value="credit">Credit</option>
                  <option value="debit">Debit</option>
                </select>
              </div>

              <div className="col-md-6 mt-3"></div>
              <div className="col-12 mt-3">
                <label htmlFor="comments">
                  <b>Comments</b>
                </label>
                <textarea
                  name="comments"
                  type="text"
                  placeholder="Enter Comment"
                  rows="5"
                  className="form-control"
                  value={formData.comments}
                  onChange={onChangeHandler}
                ></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Expense
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
