import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_advance() {
  const title = "Add Advance";
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [formData, setFormData] = useState({
    id_user: "",
    date: null,
    id_hub: "",
    advance_amount: "",
    month: "",
    comments: "",
  });

  const [errors, setErrors] = useState({});

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.hubs);
        setHubs(response.data.hubs);
        console.log("Hubs fetched:", response.data.hubs);
      } catch (error) {
        console.error("Error fetching hubs:", error);
        setHubs([]);
      }
    };
    fetchHubs();
  }, []);
  const handleHubChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_hub: selectedOption ? selectedOption.value : "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.id_user) {
      newErrors.id_user = "User ID is required.";
    }
    if (!formData.advance_amount) {
      newErrors.advance_amount = "Advance Amount is required.";
    }
    if (!formData.month) {
      newErrors.month = "Month is required.";
    }
    if (!formData.date) {
      newErrors.date = "Date is required.";
    }
    if (!formData.id_hub) {
      newErrors.id_hub = "Hub is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    data.append("id_user", formData.id_user);
    data.append("comments", formData.comments);
    data.append("date", formData.date);
    data.append("advance_amount", formData.advance_amount);
    data.append("month", formData.month);
    data.append("id_hub", formData.id_hub);

    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/advance-money/new`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/advance");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      //   console.error("Error:", error);

      console.error("Error:", error.response ? error.response.data : error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User Name *</b>
                </label>
                <Select
                  name="id_user"
                  options={users.map((user) => ({
                    value: user._id,
                    label: `${user.name || "Unknown Name"} (${
                      user.phonenumber || "Unknown Phone"
                    })`,
                  }))}
                  onChange={handleSelectChange}
                  classNamePrefix="select"
                />
                {errors.id_user && (
                  <div className="invalid-feedback">{errors.id_user}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_hub">
                  <b>Assign Hub *</b>
                </label>
                <Select
                  name="id_hub"
                  options={hubs.map((hub) => ({
                    value: hub._id,
                    label: `${hub.name}`,
                  }))}
                  onChange={handleHubChange} // Use the specific handler
                  classNamePrefix="select"
                />
                {errors.id_hub && (
                  <div className="invalid-feedback">{errors.id_hub}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="amount">
                  <b>Advance Amount *</b>
                </label>
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control"
                  name="advance_amount"
                  onChange={onChangeHandler}
                  value={formData.advance_amount || ""}
                />
                {errors.advance_amount && (
                  <p className="text-danger">{errors.advance_amount}</p>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="amount">
                  <b>Month *</b>
                </label>
                <input
                  type="number"
                  placeholder="Month"
                  className="form-control"
                  name="month"
                  onChange={onChangeHandler}
                  value={formData.month || ""}
                />
                {errors.month && <p className="text-danger">{errors.month}</p>}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="date">
                  <b>Date *</b>
                </label>
                <input
                  type="date"
                  name="date"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.date || ""}
                />
                {errors.joiningDate && (
                  <p className="text-danger">{errors.date}</p>
                )}
              </div>

              <div className="col-md-6 mt-3"></div>
              <div className="col-12 mt-3">
                <label htmlFor="comments">
                  <b>Comments</b>
                </label>
                <textarea
                  name="comments"
                  type="text"
                  placeholder="Enter Comment"
                  rows="5"
                  className="form-control"
                  value={formData.comments}
                  onChange={onChangeHandler}
                ></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Advance
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
