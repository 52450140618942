import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/advances/Data Table";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Update_data_modal from "../../components/manageInvoice/update_data_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Add_data_modal from "../../components/advances/update_data_modal";
import Layouts from "../../layouts/layouts";
import AdvanceDetailModal from "../../components/advances/Advance Details";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export
import Switch from "react-switch";

export default function Advance() {
  const title = "Advance Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showAdvanceDetailModal, setShowAdvanceDetailModal] = useState(false);
  const [selectedAdvance, setSelectedAdvance] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      name: <b>User Name</b>,
      selector: (row) => (row.id_user ? row.id_user.name : "N/A"),
      sortable: true,
    },

    {
      name: <b>Date </b>,
      selector: (row) => formatDate(row.date),
      sortable: true,
    },

    {
      name: <b>Hubs</b>,
      selector: (row) => (row.id_hub ? row.id_hub.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Amount </b>,
      selector: (row) => row.advance_amount,
      sortable: true,
    },
    {
      name: <b>Month </b>,
      selector: (row) => row.month,
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          {row.status === "created" && (
            <>
              <button
                onClick={() => copyLink()}
                className=" btn btn-success btn-sm"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              <button className=" btn btn-primary btn-sm ms-1">
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
            </>
          )}
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className=" btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewAdvance(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchAdvance = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/advance-money`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.advance)) {
        setApiState(response.data.advance);
        console.log(response.data);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.log("Error fetching advance:", error);
    }
  };

  useEffect(() => {
    fetchAdvance(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/advance-money/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Advance has been deleted.", "success");
            fetchAdvance(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Advance could not be deleted.", "error");
            console.error("Error deleting advance:", error);
          });
      }
    });
  };

  const updateAdvance = (updatedAdvance) => {
    setApiState((prev) =>
      prev.map((advance) =>
        advance._id === updateAdvance._id ? updateAdvance : advance
      )
    );
  };

  const handleUpdateAdvance = (updatedAdvanceData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/advance-money/${updatedAdvanceData._id}`,
        updatedAdvanceData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        Swal.fire("Updated!", "Advance details have been updated.", "success");
        updateAdvance(response.data.advance);
      })
      .catch((error) => {
        Swal.fire("Error!", "Advance details could not be updated.", "error");
        console.error(
          "Error updating advance:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const viewAdvance = (advance) => {
    setSelectedAdvance(advance);
    setShowAdvanceDetailModal(true);
  };

  const exportToExcel = () => {
    const transformedData = apistate.map((advance) => ({
      "User Name": advance.id_user ? advance.id_user.name : "No User",
      Hub: advance.id_hub ? advance.id_hub.name : "No HUb",
      Amount: advance.advance_amount ? advance.advance_amount : "No Amount",
      Month: advance.month ? advance.month : "Month",
      Date: advance.date ? advance.date : "Date",
      Comments: advance.comments ? advance.comments : "Comments",
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "advance");

    XLSX.writeFile(workbook, "advance.xlsx");
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateAdvance={updateAdvance}
      />
      <AdvanceDetailModal
        show={showAdvanceDetailModal}
        onHide={() => setShowAdvanceDetailModal(false)}
        advance={selectedAdvance}
      />

      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create_advance">
                    <div className="btn btn-info btn-sm text-white me-2">
                      Add Advance
                    </div>
                  </Link>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm text-white"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    apidata={apistate}
                    // columns={apicol}
                    columns={columns}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
