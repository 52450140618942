import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Layouts from "../../layouts/layouts";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  InfoWindow,
} from "@react-google-maps/api";
import moment from "moment";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const jitterAmount = 0.0001; // Amount to slightly offset coordinates to prevent overlap

// Function to slightly jitter coordinates to avoid overlapping markers
const jitterCoordinates = (coord, index) => {
  const jitter = (index % 2 === 0 ? 1 : -1) * jitterAmount * (index + 1);
  return {
    ...coord,
    lat: coord.lat + jitter,
    lng: coord.lng + jitter,
  };
};

export default function MyMapComponent() {
  const { id } = useParams();
  const location = useLocation();
  const mapRef = useRef(null); // Ref to Google Map instance
  const [pathCoordinates, setPathCoordinates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    location.state?.date || new Date().toISOString().slice(0, 10) // Default to today if no date is provided
  );
  const [selectedMarker, setSelectedMarker] = useState(null); // For InfoWindow

  useEffect(() => {
    const { date } = location.state || {};
    if (date) {
      setSelectedDate(date);
      fetchVehicleData(date);
    }
  }, [location.state]);

  const fetchVehicleData = async (date) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicleHistory/vehicle/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API RESPONSE", response.data);

      const locationData = response.data.vehicleHistory || [];
      console.log("LOCATION DATA", locationData);

      // Filter location data for the selected date
      const coordinates = locationData
        .filter(
          (location) => moment(location.lastDate).format("YYYY-MM-DD") === date
        )
        .map((location) => {
          if (
            location.coordinates &&
            location.coordinates.latitude &&
            location.coordinates.longitude
          ) {
            return {
              lat: parseFloat(location.coordinates.latitude),
              lng: parseFloat(location.coordinates.longitude),
              locationName: location.locationName || "Unknown location", // Assuming the API has a field for location name
            };
          }
          return null;
        })
        .filter((coord) => coord !== null);

      console.log("Filtered Coordinates", coordinates);

      setPathCoordinates(coordinates);
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleInfoWindowCloseClick = () => {
    setSelectedMarker(null);
  };

  const validPathCoordinates = Array.isArray(pathCoordinates)
    ? pathCoordinates
        .map((coord) => ({
          lat: parseFloat(coord.lat),
          lng: parseFloat(coord.lng),
          locationName: coord.locationName,
        }))
        .filter((coord) => !isNaN(coord.lat) && !isNaN(coord.lng))
    : [];

  useEffect(() => {
    // Adjust the map to fit the polyline bounds
    if (mapRef.current && validPathCoordinates.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      validPathCoordinates.forEach((coord) => {
        bounds.extend(coord);
      });
      mapRef.current.fitBounds(bounds); // Adjust the map to the bounds of the polyline
    }
  }, [validPathCoordinates]);

  return (
    <>
      <Layouts />
      <LoadScript googleMapsApiKey={process.env.REACT_APP_BASE_API}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={
            validPathCoordinates[0] || { lat: 30.7067034, lng: 76.6877915 }
          } // Default to center if no data
          zoom={10}
          onLoad={(map) => (mapRef.current = map)} // Store the map instance
        >
          {loading ? (
            <div>Loading...</div>
          ) : (
            validPathCoordinates.length > 0 && (
              <>
                {validPathCoordinates.map((coord, index) => {
                  const jitteredCoord = jitterCoordinates(coord, index); // Apply jitter to avoid overlap
                  return (
                    <Marker
                      key={index}
                      position={jitteredCoord}
                      label={(index + 1).toString()}
                      onClick={() => handleMarkerClick(jitteredCoord)} // Handle click to show InfoWindow
                    />
                  );
                })}
                <Polyline
                  path={validPathCoordinates}
                  options={{
                    strokeColor: "#0000FF", // Bold color
                    strokeOpacity: 1.0,
                    strokeWeight: 6, // Increase the polyline thickness for boldness
                  }}
                />

                {selectedMarker && (
                  <InfoWindow
                    position={selectedMarker}
                    onCloseClick={handleInfoWindowCloseClick}
                  >
                    <div>
                      <h4>{selectedMarker.locationName}</h4>
                      <p>
                        Coordinates: {selectedMarker.lat}, {selectedMarker.lng}
                      </p>
                    </div>
                  </InfoWindow>
                )}
              </>
            )
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
}
