import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/activeinactives/Data Table Component";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export

export default function ActiveInactive() {
  const title = "Active Inactive Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      name: <b>Vehicle</b>,
      selector: (row) => row.id_vehicle?.numberplate,
      sortable: true,
    },
    {
      name: <b>Active Date</b>,
      selector: (row) => formatDate(row.dates?.active), // Format date
      sortable: true,
    },
    {
      name: <b>Inactive Date</b>,
      selector: (row) => formatDate(row.dates?.inactive), // Format date
      sortable: true,
    },
    {
      name: <b>Description</b>,
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          {row.status === "created" && (
            <>
              <button
                onClick={() => copyLink()}
                className=" btn btn-success btn-sm"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              <button className=" btn btn-primary btn-sm ms-1">
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
            </>
          )}
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const fetchActiveInactive = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      console.log("Fetching data from API...");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/active/inactive/vehicles`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);
      if (Array.isArray(response.data.data)) {
        setApiState(response.data.data);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/active/inactive/vehicle/${row._id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Data has been deleted.", "success");
            fetchActiveInactive(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Data could not be deleted.", "error");
            console.error("Error deleting data:", error);
          });
      }
    });
  };

  const exportToExcel = () => {
    const transformedData = apistate.map((activeinactive) => ({
      "Vehicle Number": activeinactive.id_vehicle?.numberplate,
      "In Active Date": activeinactive.dates?.inactive,
      "Active Date": activeinactive.dates?.active,

      Description: activeinactive.description,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ActiveInactive");

    XLSX.writeFile(workbook, "Active-Inactive.xlsx");
  };

  useEffect(() => {
    fetchActiveInactive(currentPage);
  }, [currentPage]);

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm text-white"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    columns={columns}
                    searchBy="VehicleNumber"
                    title_btn={true}
                    apidata={apistate}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
