import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

export default function FilterModal({
  show,
  onHide,
  applyFilters,
  removeFilters,
  hubFilter,
  setHubFilter,
  userFilter,
  setUserFilter,
  vehicleFilter,
  setVehicleFilter,
  clientFilter,
  setClientFilter,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) {
  const [hubs, setHubs] = useState([]);
  const [clients, setClients] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [users, setUsers] = useState([]);

  // Fetch hubs from the backend
  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHubs(response.data.hubs);
      } catch (error) {
        console.error("Error fetching hubs:", error);
      }
    };
    fetchHubs();
  }, []);

  // Fetch clients from the backend
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setClients(response.data.clients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, []);

  // Fetch vehicles from the backend
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVehicles(response.data.vehicles);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };
    fetchVehicles();
  }, []);

  // Fetch users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  // Convert fetched hubs, clients, vehicles, and users to select option format
  const hubOptions = hubs.map((hub) => ({
    value: hub._id,
    label: hub.name,
  }));

  const clientOptions = clients.map((client) => ({
    value: client._id,
    label: client.name,
  }));

  const vehicleOptions = vehicles.map((vehicle) => ({
    value: vehicle._id,
    label: vehicle.numberplate,
  }));

  const userOptions = users.map((user) => ({
    value: user._id,
    label: user.name,
  }));

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="fromDate">
            <Form.Label>From Date</Form.Label>
            <Form.Control
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="toDate">
            <Form.Label>To Date</Form.Label>
            <Form.Control
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="hubFilter">
            <Form.Label>Hubs</Form.Label>
            <Form.Control
              as="select"
              value={hubFilter}
              onChange={(e) => setHubFilter(e.target.value)}
            >
              <option value="">All Hubs</option>
              {hubOptions.map((hub) => (
                <option key={hub.value} value={hub.value}>
                  {hub.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="vehicleFilter">
            <Form.Label>Vehicles</Form.Label>
            <Form.Control
              as="select"
              value={vehicleFilter}
              onChange={(e) => setVehicleFilter(e.target.value)}
            >
              <option value="">All Vehicles</option>
              {vehicleOptions.map((vehicle) => (
                <option key={vehicle.value} value={vehicle.value}>
                  {vehicle.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="clientFilter">
            <Form.Label>Clients</Form.Label>
            <Form.Control
              as="select"
              value={clientFilter}
              onChange={(e) => setClientFilter(e.target.value)}
            >
              <option value="">All Clients</option>
              {clientOptions.map((client) => (
                <option key={client.value} value={client.value}>
                  {client.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="userFilter">
            <Form.Label>Users</Form.Label>
            <Form.Control
              as="select"
              value={userFilter}
              onChange={(e) => setUserFilter(e.target.value)}
            >
              <option value="">All Users</option>
              {userOptions.map((user) => (
                <option key={user.value} value={user.value}>
                  {user.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
