import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { State } from "country-state-city";

export default function FilterModal({
  show,
  onHide,
  applyFilters,
  removeFilters,
  apidata,
}) {
  const [filteredCities, setFilteredCities] = useState([]);

  const getStateName = (stateCode) => {
    const state = State.getStateByCodeAndCountry(stateCode, "IN");
    return state ? state.name : stateCode;
  };

  useEffect(() => {}, [apidata]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form></Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
