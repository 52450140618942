import React from "react";
import { Modal, Button } from "react-bootstrap";

const ExpenseDetailModal = ({ show, onHide, expense }) => {
  if (!expense) return null;

  console.log("Expense object:", expense);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Expense Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Name:</strong>{" "}
          {expense.id_user ? expense.id_user.name : "N/A"}
        </p>
        <p>
          <strong>Hub:</strong> {expense.id_hub ? expense.id_hub.name : "N/A"}
        </p>
        <p>
          <strong>Transaction Type:</strong> {expense.transactionType}
        </p>
        <p>
          <strong>Category:</strong>{" "}
          {expense.id_expenseCategory ? expense.id_expenseCategory.name : "N/A"}
        </p>
        <p>
          <strong>Amount:</strong> {expense.amount}
        </p>
        {console.log("Created By field:", expense.createdBy)}

        <p>
          <strong>Created By:</strong>{" "}
          {expense.createdBy && (
            <>
              <span>
                <strong>Name:</strong> {expense.createdBy.user_email}
              </span>
              <span>, </span>
              <span>
                <strong>Role:</strong> {expense.createdBy.user_role}
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Comments:</strong> {expense.comments}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExpenseDetailModal;
