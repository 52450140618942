import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import { Country, State, City } from "country-state-city";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

export default function Create_salary() {
  const title = "Add Salary";
  const [formData, setFormData] = useState({
    id_user: "",
    id_employee: "",
    basic_pay: "",
    variable_pay: "",
    total_salary: "",
    PF: "",
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const brad = [{ name: "Home" }, { name: title }];
  const [users, setUsers] = useState([]);

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  const handleUserChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_user: selectedOption ? selectedOption.value : "",
    }));
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.id_user) newErrors.id_user = "User Name is required";
    if (!formData.id_employee) newErrors.address = "Employee Id is required";
    if (!formData.total_salary)
      newErrors.total_salary = "Total Salary is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    data.append("id_user", formData.id_user);
    data.append("id_employee", formData.id_employee);
    data.append("basic_pay", formData.basic_pay);
    data.append("variable_pay", formData.variable_pay);
    data.append("total_salary", formData.total_salary);
    data.append("PF", formData.PF);

    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/salary/new`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/salary");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      //   console.error("Error:", error);

      console.error("Error:", error.response ? error.response.data : error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User * </b>
                </label>
                <Select
                  name="id_user"
                  options={users.map((user) => ({
                    value: user._id,
                    label: `${user.name || "Unknown Name"} (${
                      user.phonenumber || "Unknown Phone"
                    })`,
                  }))}
                  onChange={handleUserChange}
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_employee">
                  <b>Employee ID *</b>
                </label>
                <input
                  type="text"
                  name="id_employee"
                  placeholder="Employee Id"
                  className="form-control"
                  value={formData.id_employee}
                  onChange={onChangeHandler}
                />
                {errors.id_employee && (
                  <small className="text-danger">{errors.id_employee}</small>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_employee">
                  <b>Basic Pay *</b>
                </label>
                <input
                  type="number"
                  name="basic_pay"
                  placeholder="Basic Pay"
                  className="form-control"
                  value={formData.basic_pay}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="variable_pay">
                  <b>Variable Pay </b>
                </label>
                <input
                  type="number"
                  name="variable_pay"
                  placeholder="Variable Pay"
                  className="form-control"
                  value={formData.variable_pay}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="total_salary">
                  <b>Total Salary *</b>
                </label>
                <input
                  type="number"
                  name="total_salary"
                  placeholder="Total Salary"
                  className="form-control"
                  value={formData.total_salary}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="total_salary">
                  <b>PF </b>
                </label>
                <input
                  type="text"
                  name="PF"
                  placeholder="PF"
                  className="form-control"
                  value={formData.PF}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Salary
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
