import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateExpense,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data({
        ...select_data,
        id_hub: select_data.id_hub ? select_data.id_hub._id : "",
        id_expenseCategory: select_data.id_expenseCategory
          ? select_data.id_expenseCategory._id
          : "",
        id_user: select_data.id_user ? select_data.id_user._id : "",
      });
    }
  }, [select_data]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };

    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHubs(response.data.hubs);
      } catch (error) {
        console.error("Error fetching hubs:", error);
        setHubs([]);
      }
    };

    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/expense-categories`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategory(response.data.expenseCategories);
      } catch (error) {
        console.error("Error fetching expenseCategories:", error);
        setCategory([]);
      }
    };

    fetchUsers();
    fetchHubs();
    fetchCategories();
  }, []);

  // Updated handleChange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdate_modal_data({
      ...update_modal_data,
      [name]: value, // Directly update the field with the name (amount, date, etc.)
    });
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_user: value,
    }));
  };

  const handleHubChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_hub: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_expenseCategory: value,
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!update_modal_data.id_user) newErrors.id_user = "User ID is required";
    if (!update_modal_data.id_hub) newErrors.id_hub = "Hub is required";
    if (!update_modal_data.amount) newErrors.amount = "Amount is required";
    if (!update_modal_data.id_expenseCategory)
      newErrors.id_expenseCategory = "Category is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          // Create a new FormData object
          const formData = new FormData();
          formData.append("id_user", update_modal_data.id_user);
          formData.append("id_hub", update_modal_data.id_hub);
          formData.append(
            "id_expenseCategory",
            update_modal_data.id_expenseCategory
          );
          formData.append("comments", update_modal_data.comments);
          formData.append("amount", update_modal_data.amount);
          formData.append("date", update_modal_data.date);
          formData.append("transactionType", update_modal_data.transactionType);

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/expense/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateExpense(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating expenses:", error);
        }
        set_update_data_modal_Show(false);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Damage Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="id_user">
              <Form.Label>User *</Form.Label>
              <Form.Control
                as="select"
                name="id_user"
                value={update_modal_data.id_user || ""}
                onChange={handleUserChange}
                isInvalid={!!errors.id_user}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.name || "Unknown Name"} (
                    {user.phonenumber || "Unknown Phone"} )
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_user}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="id_hub">
              <Form.Label>Hub *</Form.Label>
              <Form.Control
                as="select"
                name="id_hub"
                value={update_modal_data.id_hub || ""}
                onChange={handleHubChange}
                isInvalid={!!errors.id_hub}
              >
                <option value="">Select Hub</option>
                {hubs.map((hub) => (
                  <option key={hub._id} value={hub._id}>
                    {hub.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_hub}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="id_expenseCategory">
              <Form.Label>Category *</Form.Label>
              <Form.Control
                as="select"
                name="id_expenseCategory"
                value={update_modal_data.id_expenseCategory || ""}
                onChange={handleCategoryChange}
                isInvalid={!!errors.id_expenseCategory}
              >
                <option value="">Select Category</option>
                {category.map((cat) => (
                  <option key={cat._id} value={cat._id}>
                    {cat.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_expenseCategory}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="transactionType">
              <Form.Label>Transaction Type *</Form.Label>
              <Form.Control
                as="select"
                name="transactionType"
                value={update_modal_data.transactionType || ""}
                onChange={handleChange}
                isInvalid={!!errors.transactionType}
              >
                <option value="">Select Transaction Type</option>
                <option value="credit">Credit</option>
                <option value="debit">Debit</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="comments">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                type="text"
                name="comments"
                value={update_modal_data.comments || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Amount *</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={update_modal_data.amount || ""}
                onChange={handleChange}
                isInvalid={!!errors.amount}
              />
              <Form.Control.Feedback type="invalid">
                {errors.amount}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="date">
              <Form.Label>Date *</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={update_modal_data.date || ""}
                onChange={handleChange}
                isInvalid={!!errors.date}
              />
              <Form.Control.Feedback type="invalid">
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
