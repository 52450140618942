import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_asset() {
  const title = "Add Assets";
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [assets, setAsset] = useState([]);
  const [formData, setFormData] = useState({
    id_user: "",
    assignedDate: null,
    id_assettype: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchAssettype = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/asset-types`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.assetTypes);
        setAsset(response.data.assetTypes);
        console.log("assets fetched:", response.data.assetTypes);
      } catch (error) {
        console.error("Error fetching asset:", error);
        setAsset([]);
      }
    };
    fetchAssettype();
  }, []);
  const handleAssetChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_assettype: selectedOption ? selectedOption.value : "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.id_user) {
      newErrors.id_user = "User ID is required.";
    }
    if (!formData.id_assettype) {
      newErrors.id_assettype = "Asset Type is required.";
    }
    if (!formData.assignedDate) {
      newErrors.assignedDate = "Assigned date is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    data.append("id_user", formData.id_user);
    data.append("description", formData.description);
    data.append("assignedDate", formData.assignedDate);
    data.append("id_assettype", formData.id_assettype);

    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/asset/new`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/asset");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      //   console.error("Error:", error);

      console.error("Error:", error.response ? error.response.data : error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User Name *</b>
                </label>
                <Select
                  name="id_user"
                  options={users.map((user) => ({
                    value: user._id,
                    label: `${user.name || "Unknown Name"} (${
                      user.phonenumber || "Unknown Phone"
                    })`,
                  }))}
                  onChange={handleSelectChange}
                  classNamePrefix="select"
                />
                {errors.id_user && (
                  <div className="invalid-feedback">{errors.id_user}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="id_assettype">
                  <b>Asset Type *</b>
                </label>
                <Select
                  name="id_assettype"
                  options={assets.map((asset) => ({
                    value: asset._id,
                    label: `${asset.assetname}`,
                  }))}
                  onChange={handleAssetChange}
                  classNamePrefix="select"
                />

                {errors.id_assettype && (
                  <div className="invalid-feedback">{errors.id_assettype}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="assignedDate">
                  <b>Assigned Date *</b>
                </label>
                <input
                  type="date"
                  name="assignedDate"
                  onChange={onChangeHandler}
                  className="form-control"
                  value={formData.assignedDate || ""}
                />
                {errors.assignedDate && (
                  <p className="text-danger">{errors.assignedDate}</p>
                )}
              </div>

              <div className="col-md-6 mt-3"></div>
              <div className="col-12 mt-3">
                <label htmlFor="description">
                  <b>Description</b>
                </label>
                <textarea
                  name="description"
                  type="text"
                  placeholder="Enter Description"
                  rows="5"
                  className="form-control"
                  value={formData.description}
                  onChange={onChangeHandler}
                ></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Description
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
