import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { Country, State, City } from "country-state-city";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateUser,
}) {
  const [ren, setRen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [tempstates, setTempstates] = useState([]);
  const [tempcities, setTempcities] = useState([]);
  const [tempselectedCountryCode, setTempselectedCountryCode] = useState("");
  const [tempselectedStateCode, setTempSelectedStateCode] = useState("");
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [roles, setRoles] = useState([]);
  const [clients, setClients] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const [generalstafftypes, setGeneralstafftypes] = useState([]); // New state for general staff types

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setSelectedCountryCode(select_data.country || "");
      setSelectedStateCode(select_data.state || "");
      setUpdate_modal_data((prevData) => ({
        ...prevData,
      }));
    }
  }, [select_data]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  useEffect(() => {
    const fetchCountries = async () => {
      const countryData = Country.getAllCountries().map((country) => ({
        name: country.name,
        code: country.isoCode,
      }));
      setCountries(countryData);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountryCode) {
      const stateData = State.getStatesOfCountry(selectedCountryCode).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      setStates(stateData);
      setCities([]);
    }
  }, [selectedCountryCode]);

  useEffect(() => {
    if (selectedStateCode && selectedCountryCode) {
      const cityData = City.getCitiesOfState(
        selectedCountryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, selectedCountryCode]);

  useEffect(() => {
    if (tempselectedCountryCode) {
      const stateData = State.getStatesOfCountry(tempselectedCountryCode).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      setTempstates(stateData);
      setTempcities([]);
    }
  }, [tempselectedCountryCode]);

  useEffect(() => {
    if (tempselectedStateCode && tempselectedCountryCode) {
      const cityData = City.getCitiesOfState(
        tempselectedCountryCode,
        tempselectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setTempcities(cityData);
    }
  }, [tempselectedStateCode, tempselectedCountryCode]);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setSelectedCountryCode(select_data.country || "");
      setSelectedStateCode(select_data.state || "");
    }
  }, [select_data]);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      const selectedClient = select_data.id_client.map((item) => ({
        value: item._id,
        label: clients.find((client) => client._id === item._id).name || "",
      }));

      console.log("Sdata", selectedClient);

      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_client: [...selectedClient],
        id_role: select_data.id_role._id,
        id_generalStaffType: select_data.id_generalStaffType._id,
        //set here
      }));

      console.log("setUpdate_modal_data", update_modal_data);
    }
  }, [select_data, roles, generalstafftypes]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  useEffect(() => {
    const fetchRoles = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/roles`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setRoles(response.data.roles || []);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    const fetchGeneralstafftypes = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff-types`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setGeneralstafftypes(response.data.generalStaffTypes || []);
      } catch (error) {
        console.error("Error fetching general staff types:", error);
      }
    };

    const fetchClients = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setClients(response.data.clients || []);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchRoles();
    fetchClients();
    fetchGeneralstafftypes();
  }, []);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setUpdate_modal_data({ ...update_modal_data, [name]: files[0] });
  };

  const handleClientChange = (selectedOptions) => {
    const clientIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    console.log("Selected Clients:", clientIds); // Debugging line

    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_client: clientIds,
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "latitude" || name === "longitude") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        coordinates: {
          ...prevData.coordinates,
          [name]: value, // Ensure numeric value
        },
      }));
    } else if (name === "id_client") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_client: { ...prevData.id_client, [name]: value },
      }));
    } else if (name === "id_role") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_role: value,
      }));
    } else if (name === "id_generalStaffType") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_generalStaffType: value, // Set directly as a single value
      }));
    } else if (name === "check_in" || name === "check_out") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        shift_hours: {
          ...prevData.shift_hours,
          [name]: value,
        },
      }));
    } else if (
      name === "account_number" ||
      name === "bank_name" ||
      name === "branch_name" ||
      name === "ifsc_code" ||
      name === "account_holder_name"
    ) {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        bank_details: {
          ...prevData.bank_details,
          [name]: value,
        },
      }));
    } else if (name === "tempcountry") {
      setTempselectedCountryCode(value);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        tempaddress: {
          ...prevData.tempaddress,
          country: value,
          state: "", // Reset state when country changes
          city: "", // Reset city when country changes
        },
      }));
    } else if (name === "tempstate") {
      setTempSelectedStateCode(value);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        tempaddress: {
          ...prevData.tempaddress,
          state: value,
          city: "", // Reset city when state changes
        },
      }));
    } else if (name === "tempcity") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        tempaddress: {
          ...prevData.tempaddress,
          city: value,
        },
      }));
    } else if (name === "tempaddress") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        tempaddress: {
          ...prevData.tempaddress,
          address: value,
        },
      }));
    } else if (name === "country") {
      setSelectedCountryCode(value);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        permanentaddress: {
          ...prevData.permanentaddress,
          country: value,
          state: "", // Reset state when country changes
          city: "", // Reset city when country changes
        },
      }));
    } else if (name === "state") {
      setSelectedStateCode(value);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        permanentaddress: {
          ...prevData.permanentaddress,
          state: value,
          city: "", // Reset city when state changes
        },
      }));
    } else if (name === "city") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        permanentaddress: {
          ...prevData.permanentaddress,
          city: value,
        },
      }));
    } else if (name === "address") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        permanentaddress: {
          ...prevData.permanentaddress,
          [name]: value,
        },
      }));
    } else if (name === "avatar") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setUpdate_modal_data((prevData) => ({
          ...prevData,
          coordinates: { latitude: lat, longitude: lng },
        }));
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!update_modal_data.name) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!update_modal_data.joiningdate)
      errors.joiningdate = "Joining Date is required";

    if (!update_modal_data.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(update_modal_data.email)) {
      errors.email = "Email address is invalid";
      isValid = false;
    }

    if (!update_modal_data.status) {
      errors.status = "Status is required";
      isValid = false;
    }

    if (!update_modal_data.id_role) {
      errors.id_role = "Role is required";
      isValid = false;
    }

    if (!update_modal_data.id_role) {
      errors.id_role = "Role is required";
      isValid = false;
    }

    if (!update_modal_data.coordinates?.longitude) {
      errors.longitude = "Longitude is required";
      isValid = false;
    }

    if (!update_modal_data.coordinates?.latitude) {
      errors.latitude = "Latitude is required";
      isValid = false;
    }

    if (!update_modal_data.phonenumber) {
      errors.phonenumber = "Phone number is required";
      isValid = false;
    }

    if (!update_modal_data.id_generalStaffType) {
      errors.id_generalStaffType = "General Staff Type is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleUpdate = async () => {
    console.log("Update button clicked");

    if (!validateForm()) {
      console.log("Form validation failed.");
      return;
    }
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          const formData = new FormData();

          if (Array.isArray(update_modal_data.id_client)) {
            update_modal_data.id_client.forEach((client, index) => {
              formData.append(`id_client[${index}]`, client.value || client);
            });
          }

          // Handle other fields
          for (const key in update_modal_data) {
            if (update_modal_data.hasOwnProperty(key)) {
              if (key === "coordinates") {
                formData.append(
                  "coordinates[longitude]",
                  update_modal_data.coordinates.longitude
                );
                formData.append(
                  "coordinates[latitude]",
                  update_modal_data.coordinates.latitude
                );
              } else if (key === "bank_details") {
                formData.append(
                  "bank_details[account_number]",
                  update_modal_data.bank_details.account_number
                );
                formData.append(
                  "bank_details[bank_name]",
                  update_modal_data.bank_details.bank_name
                );
                formData.append(
                  "bank_details[branch_name]",
                  update_modal_data.bank_details.branch_name
                );
                formData.append(
                  "bank_details[ifsc_code]",
                  update_modal_data.bank_details.ifsc_code
                );
                formData.append(
                  "bank_details[account_holder_name]",
                  update_modal_data.bank_details.account_holder_name
                );
              } else if (key === "tempaddress") {
                formData.append(
                  "tempaddress[address]",
                  update_modal_data.tempaddress.address
                );
                formData.append(
                  "tempaddress[country]",
                  update_modal_data.tempaddress.country
                );
                formData.append(
                  "tempaddress[state]",
                  update_modal_data.tempaddress.state
                );
                formData.append(
                  "tempaddress[city]",
                  update_modal_data.tempaddress.city
                );
              } else if (key === "permanentaddress") {
                formData.append(
                  "permanentaddress[address]",
                  update_modal_data.permanentaddress.address
                );
                formData.append(
                  "permanentaddress[country]",
                  update_modal_data.permanentaddress.country
                );
                formData.append(
                  "permanentaddress[state]",
                  update_modal_data.permanentaddress.state
                );
                formData.append(
                  "permanentaddress[city]",
                  update_modal_data.permanentaddress.city
                );
              } else if (key === "shift_hours") {
                formData.append(
                  "shift_hours[check_in]",
                  update_modal_data.shift_hours.check_in
                );
                formData.append(
                  "shift_hours[check_out]",
                  update_modal_data.shift_hours.check_out
                );
              } else if (key === "id_generalStaffType") {
                formData.append(
                  "id_generalStaffType",
                  update_modal_data.id_generalStaffType
                );
              } else if (key === "email") {
                formData.append("email", update_modal_data.email);
              } else if (key === "status") {
                formData.append("status", update_modal_data.status);
              } else if (key === "name") {
                formData.append("name", update_modal_data.name);
              } else if (key === "phonenumber") {
                formData.append("phonenumber", update_modal_data.phonenumber);
              } else if (key === "role") {
                formData.append("role", update_modal_data.role);
              } else if (key === "avatar") {
                if (update_modal_data.avatar && update_modal_data.avatar[0]) {
                  formData.append(key, update_modal_data.avatar[0]);
                }
              } else if (key !== "attendance" && key !== "id_client") {
                formData.append(key, update_modal_data[key]);
              }
              console.log("FORMDATA=", formData);
            }
          }
          console.log(
            "FORMADAAT to be sent",
            Object.fromEntries(formData.entries())
          );

          // Make the update request
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/user/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("THIS IS THE RESPONSE", res.data);

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateUser(update_modal_data);
            set_update_data_modal_Show(false);
            setRen(!ren);
          } else {
            Swal.fire(
              "Update failed",
              res.data.message || "Unknown error",
              "error"
            );
          }
        } catch (error) {
          console.error("Error updating user data:", error);
          Swal.fire(
            "Error!",
            "An error occurred while updating user data.",
            "error"
          );
        }
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name *</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={update_modal_data.name || ""}
                onChange={handleChange}
                placeholder="Enter Name"
              />
              {errors.name && <p className="text-danger">{errors.name}</p>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>User Email *</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={update_modal_data.email || ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Joining Date *</Form.Label>
              <Form.Control
                type="date"
                name="joiningdate"
                value={update_modal_data.joiningdate || ""}
                onChange={handleChange}
              />
              {errors.joiningdate && (
                <small className="text-danger">{errors.joiningdate}</small>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status *</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={update_modal_data.status || ""}
                onChange={handleChange}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Shift-in Time *</Form.Label>
              <Form.Control
                type="time"
                name="check_in"
                value={update_modal_data.shift_hours?.check_in}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.shift_in}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Shift-out Time *</Form.Label>
              <Form.Control
                type="time"
                name="check_out"
                value={update_modal_data.shift_hours?.check_out}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.shift_out}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role *</Form.Label>
              <select
                name="id_role"
                id="id_role"
                className="form-control mt-2"
                onChange={handleChange}
                value={update_modal_data.id_role || ""}
              >
                <option value="">Select Role</option>
                {Array.isArray(roles) &&
                  roles.map((role) => (
                    <option key={role.id} value={role._id}>
                      {role.role_title}
                    </option>
                  ))}
              </select>
              <Form.Control.Feedback type="invalid">
                {errors.id_role}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Longitude *</Form.Label>
              <Form.Control
                type="text"
                name="longitude"
                value={update_modal_data.coordinates?.longitude || ""}
                onChange={handleChange}
              />
              {errors.coordinates && (
                <p className="text-danger">{errors.coordinates}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Latitude *</Form.Label>
              <Form.Control
                type="text"
                name="latitude"
                value={update_modal_data.coordinates?.latitude || ""}
                onChange={handleChange}
              />
              {errors.coordinates && (
                <p className="text-danger">{errors.coordinates}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Location *</Form.Label>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_BASE_API}
                libraries={["places"]}
              >
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <input
                    type="text"
                    placeholder="Search places..."
                    className="form-control"
                  />
                </Autocomplete>
              </LoadScript>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone Number *</Form.Label>
              <input
                type="number"
                className="form-control"
                name="phonenumber"
                value={update_modal_data.phonenumber || ""}
                onChange={handleChange}
              />
              {errors.phonenumber && (
                <p className="text-danger">{errors.phonenumber}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Account Number *</Form.Label>
              <Form.Control
                type="text"
                name="account_number"
                value={update_modal_data.bank_details?.account_number}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.account_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Bank Name *</Form.Label>
              <Form.Control
                type="text"
                name="bank_name"
                value={update_modal_data.bank_details?.bank_name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bank_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Branch Name *</Form.Label>
              <Form.Control
                type="text"
                name="branch_name"
                value={update_modal_data.bank_details?.branch_name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.branch_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>IFSC Code *</Form.Label>
              <Form.Control
                type="text"
                name="ifsc_code"
                value={update_modal_data.bank_details?.ifsc_code}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ifsc_code}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Account Holder Name *</Form.Label>
              <Form.Control
                type="text"
                name="account_holder_name"
                value={update_modal_data.bank_details?.account_holder_name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.account_holder_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Temporary Address *</Form.Label>
              <Form.Control
                type="text"
                name="tempaddress"
                value={update_modal_data.tempaddress?.address || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Temporary Country *</Form.Label>
              <Form.Control
                as="select"
                name="tempcountry"
                value={update_modal_data.tempaddress?.country || ""}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
              {errors.country && (
                <small className="text-danger">{errors.country}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Temporary State *</Form.Label>
              <Form.Control
                as="select"
                name="tempstate"
                value={update_modal_data.tempaddress?.state || ""}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {tempstates.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
              {errors.state && (
                <small className="text-danger">{errors.state}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Temporary City *</Form.Label>
              <Form.Control
                as="select"
                name="tempcity"
                value={update_modal_data.tempaddress?.city || ""}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {tempcities.map((city) => (
                  <option key={city.code} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </Form.Control>
              {errors.city && (
                <small className="text-danger">{errors.city}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Permanent Address *</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={update_modal_data.permanentaddress?.address || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Permanent Country *</Form.Label>
              <Form.Control
                as="select"
                name="country"
                value={update_modal_data.permanentaddress?.country || ""}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Permanent State *</Form.Label>
              <Form.Control
                as="select"
                name="state"
                value={update_modal_data.permanentaddress?.state || ""}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Permanent City *</Form.Label>
              <Form.Control
                as="select"
                name="city"
                value={update_modal_data.permanentaddress?.city || ""}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.code} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>General Staff Type *</Form.Label>
              <select
                name="id_generalStaffType"
                className="form-control"
                value={update_modal_data.id_generalStaffType || ""}
                onChange={handleChange}
              >
                <option value="">Select Staff Type</option>
                {Array.isArray(generalstafftypes) &&
                  generalstafftypes.map((generalstafftype) => (
                    <option
                      key={generalstafftype._id}
                      value={generalstafftype._id}
                    >
                      {generalstafftype.name}
                    </option>
                  ))}
              </select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Client *</Form.Label>
              <Select
                name="id_client"
                defaultValue={update_modal_data.id_client || ""}
                options={clients.map((client) => ({
                  value: client._id,
                  label: client.name,
                }))}
                isMulti
                onChange={handleClientChange}
                className="basic-multi-select"
                // className={`basic-multi-select ${
                //   errors.id_client ? "is-invalid" : ""
                // }`}
                classNamePrefix="select"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Avatar </Form.Label>
              <Form.Control
                type="file"
                name="avatar"
                accept="image/*"
                onChange={handleChange}
              />
            </Form.Group>
            {/* Image Preview */}
            {(update_modal_data.avatar && update_modal_data.avatar[0]) ||
            select_data.avatar?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.avatar &&
                      update_modal_data.avatar[0] instanceof File
                        ? URL.createObjectURL(update_modal_data.avatar[0])
                        : select_data.avatar?.url
                    }
                    alt="Avatar Image"
                    style={{ maxWidth: "50%", height: "30%" }}
                  />
                </div>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update User
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
