import React from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";

const UserDetailModal = ({ show, onHide, user }) => {
  if (!user) return null;
  console.log(user);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (datetimeString) => {
    if (!datetimeString) return "";

    const date = new Date(datetimeString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {user.avatar && (
          <div className="text-center mb-3">
            <img
              src={user.avatar?.url}
              alt="User Avatar"
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            />
          </div>
        )}
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        <p>
          <strong>Name:</strong> {user.name || "N/A"}
        </p>
        <p>
          <strong>Phone Number:</strong> {user.phonenumber || "N/A"}
        </p>
        <p>
          <strong>Joining Date :</strong>{" "}
          {user.joiningdate
            ? new Date(user.joiningdate).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : "N/A"}
        </p>
        <p>
          <strong>Staff Type:</strong> {user.id_generalStaffType?.name || "N/A"}
        </p>
        <p>
          <strong>Status:</strong> {user.status}
        </p>
        <p>
          <strong>Role:</strong> {user.id_role?.role_title || "N/A"}
        </p>
        <p>
          <strong>Check In:</strong> {formatTime(user.attendance?.check_in)}
        </p>
        <p>
          <strong>Check Out:</strong> {formatTime(user.attendance?.check_out)}
        </p>
        <p>
          <strong>Shift In:</strong> {user.shift_hours?.check_in}
        </p>
        <p>
          <strong>Shift Out:</strong> {user.shift_hours?.check_out}
        </p>
        <p>
          <strong>Logged In:</strong> {user.isLoggedIn ? "Yes" : "No"}
        </p>
        <p>
          <strong>Client Name:</strong>
          {Array.isArray(user.id_client) && user.id_client.length > 0 ? (
            user.id_client.map((client, index) => (
              <div key={client._id}>
                <span>
                  Client {index + 1} - {client.name}
                </span>
                {client.phonenumber && <span>, {client.phonenumber}</span>}
              </div>
            ))
          ) : (
            <span> N/A</span>
          )}
        </p>
        <p>
          <strong>Account Number :</strong> {user.bank_details?.account_number}
        </p>
        <p>
          <strong>Bank Name :</strong> {user.bank_details?.bank_name}
        </p>
        <p>
          <strong>Branch Name :</strong> {user.bank_details?.branch_name}
        </p>
        <p>
          <strong>IFSC CODE :</strong> {user.bank_details?.ifsc_code}
        </p>
        <p>
          <strong>Account Holder Name :</strong>{" "}
          {user.bank_details?.account_holder_name}
        </p>
        <p>
          <strong>Temporary Address :</strong> {user.tempaddress?.address}
        </p>
        <p>
          <strong>Temporary Country :</strong> {user.tempaddress?.country}
        </p>
        <p>
          <strong>Temporary State :</strong> {user.tempaddress?.state}
        </p>
        <p>
          <strong>Temporary City :</strong> {user.tempaddress?.city}
        </p>

        <p>
          <strong>Permanent Address :</strong> {user.permanentaddress?.address}
        </p>
        <p>
          <strong>Permanent Country :</strong> {user.permanentaddress?.country}
        </p>
        <p>
          <strong>Permanent State :</strong> {user.permanentaddress?.state}
        </p>
        <p>
          <strong>Permanent City :</strong> {user.permanentaddress?.city}
        </p>
        <p>
          <strong>Working Hours:</strong> {user.workinghours || "N/A"}
        </p>
        <p>
          <strong>Leaves:</strong> {user.id_leaves || "N/A"}
        </p>
        <p>
          <strong>Created Date:</strong> {formatDate(user.createdAt)}
        </p>
        <p>
          <strong>Modified Date:</strong> {formatDate(user.updatedAt)}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserDetailModal;
