import React from "react";
import { Modal, Button } from "react-bootstrap";

const AssetDetailModal = ({ show, onHide, asset }) => {
  if (!asset) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Asset Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Name:</strong> {asset.assetname}
        </p>
        <p>
          <strong>Cost:</strong> {asset.cost}
        </p>
        <p>
          <strong>Descriptions:</strong> {asset.description}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssetDetailModal;
