import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateAsset,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [asset, setAsset] = useState([]);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data({
        ...select_data,
        id_assettype: select_data.id_assettype
          ? select_data.id_assettype._id
          : "",
        id_user: select_data.id_user ? select_data.id_user._id : "",
      });
    }
  }, [select_data]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };
  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_user: value,
    }));
  };
  const handleAssetChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_assettype: value,
    }));
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };

    const fetchAsset = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/asset-types`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAsset(response.data.assetTypes);
      } catch (error) {
        console.error("Error fetching assetTypes:", error);
        setAsset([]);
      }
    };

    fetchUsers();
    fetchAsset();
  }, []);
  // Updated handleChange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdate_modal_data({
      ...update_modal_data,
      [name]: value, // Directly update the field with the name (amount, date, etc.)
    });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!update_modal_data.id_assettype)
      newErrors.id_assettype = "Asset Type is required";
    if (!update_modal_data.id_user) newErrors.id_user = "User Id is required";
    if (!update_modal_data.assignedDate)
      newErrors.assignedDate = "Date is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          // Create a new FormData object
          const formData = new FormData();
          formData.append("id_assettype", update_modal_data.id_assettype);
          formData.append("assignedDate", update_modal_data.assignedDate);
          formData.append("id_user", update_modal_data.id_user);
          formData.append("description", update_modal_data.description);

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/asset/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateAsset(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating asset:", error);
        }
        set_update_data_modal_Show(false);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Advance Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="id_user">
              <Form.Label>User *</Form.Label>
              <Form.Control
                as="select"
                name="id_user"
                value={update_modal_data.id_user || ""}
                onChange={handleUserChange}
                isInvalid={!!errors.id_user}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.name || "Unknown Name"} (
                    {user.phonenumber || "Unknown Phone"} )
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_user}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="id_assettype">
              <Form.Label>Asset Type *</Form.Label>
              <Form.Control
                as="select"
                name="id_assettype"
                value={update_modal_data.id_assettype || ""}
                onChange={handleAssetChange}
                isInvalid={!!errors.id_assettype}
              >
                <option value="">Select Asset</option>
                {asset.map((asset) => (
                  <option key={asset._id} value={asset._id}>
                    {asset.assetname}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_assettype}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Assigned Date *</Form.Label>
              <Form.Control
                type="date"
                name="assignedDate"
                value={update_modal_data.assignedDate || ""}
                onChange={handleChange}
              />
              {errors.assignedDate && (
                <small className="text-danger">{errors.assignedDate}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="comments">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={update_modal_data.description || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
